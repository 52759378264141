export interface ValidationResult {
    isValid: boolean;
    validationMessage: string;
}

export interface Validable {
    validate(fieldName: string): ValidationResult;
    validateAll(): ValidationResult;
}

export function isEmailValid(email: string): boolean {
    return (email === '' || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email));
}

export function isPhoneValid(phone: string): boolean {
    return (phone === '' || /^(6|2)[0-9\b]{8}$/.test(phone));
}