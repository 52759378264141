import PageResult from "../utils/PageResult";

export default class ApiHelper {
    static pageResultMapper<R>(data: any, contentMapper: (contentData: any) => R | undefined) {
        if(!data) return undefined;
        let content: Array<R> = [];
        data.content.forEach((it: any) => {
            let mapedR = contentMapper(it);
            if(mapedR) {
                content.push(mapedR);
            }
        })
        let result: PageResult<R> = new PageResult(data.page, data.totalPages, data.totalElements, content);
        return result;
    }
        
}