import Inventory from "./Inventory";
import Lot from "./Lot";
import Medoc from "./Medoc";

export default class InventoryItem {
	public id?: number;	
	public version: number;
	public inventory: Inventory;
	public medoc: Medoc;	
	public lot: Lot;	
	public knownQuantity: number;	
	public realQuantity?: number;

	constructor(inventory: Inventory, medoc: Medoc, lot: Lot, knownQuantity: number, realQuantity?: number, version: number = 1) {
		this.inventory = inventory;
		this.medoc = medoc;
		this.lot = lot;
		this.knownQuantity = knownQuantity;
		this.realQuantity = realQuantity;
		this.version = version;
	}

}
