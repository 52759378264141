import BloodType from "../../../utils/enums/BloodType";
import MaritalStatus from "../../../utils/enums/MaritalStatus";
import Sex from "../../../utils/enums/Sex";
import { ValidationResult } from "../../../utils/Validable";
import DiscountPlan from "../../accounting/model/DiscountPlan";
import PriceList from "../../accounting/model/PriceList";
import SimpleVisit from "../../healthcare/model/SimpleVisit";

export default class Patient {
	public id?: number;	
	public version: number;
	public code?: string;	
	public lastName: string;	
	public firstName?: string;	
	public sex: Sex;
	public dateOfBirth?: Date;	
	public bloodType?: BloodType;	
	public maritalStatus?: MaritalStatus;	
	public inArea: boolean;	
	public numberOfChildren?: number;
	public placeOfBirth?: string;
	public profession?: string;
	public profileImage?: string;
	public personToContact?: string;
	public personToContactAddress?: string;
	public personToContactPhone?: string;
	public city?: string;
	public address?: string;
	public phone?: string;
	public note?: string;
	public priceList?: PriceList;	
	public discountPlan?: DiscountPlan;
	public currentVisit?: SimpleVisit;

	constructor(lastName: string, sex: Sex = Sex.M, isInArea = true, version: number = 1) {
		this.lastName = lastName;
		this.sex = sex;
		this.inArea = isInArea;
		this.version = version;
	}
	
	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "lastName") {
			if(!this.lastName || !this.lastName.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "errors in form"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		return this.validate("lastName");
	}
	
	clone() {
		let pt = new Patient(this.lastName, this.sex, this.inArea, this.version);
		pt.id = this.id;
		pt.code = this.code;
		pt.firstName = this.firstName;
		pt.dateOfBirth = this.dateOfBirth;
		pt.bloodType = this.bloodType;
		pt.maritalStatus = this.maritalStatus;
		pt.numberOfChildren = this.numberOfChildren;
		pt.placeOfBirth = this.placeOfBirth;
		pt.profession = this.profession;
		pt.profileImage = this.profileImage;
		pt.personToContact = this.personToContact;
		pt.personToContactAddress = this.personToContactAddress;
		pt.personToContactPhone = this.personToContactPhone;
		pt.city = this.city;
		pt.address = this.address;
		pt.phone = this.phone;
		pt.note = this.note;
		if(this.priceList) {
			pt.priceList = this.priceList.clone();
		}
		if(this.discountPlan) {
			pt.discountPlan = this.discountPlan.clone();
		}
		if(this.currentVisit) {
			pt.currentVisit = this.currentVisit.clone();
		}
		return pt;
	}

	getFullName() {
		if(this.lastName) {
			return this.firstName? this.lastName + " " + this.firstName : this.lastName;
		}
		else if(this.firstName) {
			return this.firstName;
		} 
		else {
			return "";
		}
	}

	getMetaData(): Map<string, string> {
		let metaData = new Map<string, string>();
		metaData.set("Code", this.code || "");
		metaData.set("Sex", this.sex === Sex.F? "Female" : "Male");
		metaData.set("Born on", this.dateOfBirth?.toLocaleDateString() || "");
		metaData.set("Born in", this.placeOfBirth || "");
		return metaData;
	}

	getMetaDataExcludeCode(): Map<string, string> {
		let metaData = new Map<string, string>();
		metaData.set("Sex", this.sex === Sex.F? "Female" : "Male");
		if(this.dateOfBirth){
			metaData.set("Born on", this.dateOfBirth.toLocaleDateString());
		}
		if(this.placeOfBirth && this.placeOfBirth.trim().length > 0){
			metaData.set("Born in", this.placeOfBirth);
		}
		return metaData;
	}
}
