import MedocType from "../../app/pharmacy/model/MedocType";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface MedocTypeState {
    medocTypes: Array<MedocType>;
}

const initialState: MedocTypeState = {
    medocTypes: []
}

export default function medocTypeReducer(state = initialState, action: Action<MedocTypeState>) {
    let nexState: MedocTypeState;
    switch (action.type) {
        case ActionTypes.ADD_MEDOC_TYPE:
            nexState = {
                ...state,
                medocTypes: merge(state.medocTypes, action.payload.medocTypes, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_MEDOC_TYPE:
            let _medocTypes: Array<MedocType> = [];
            state.medocTypes.forEach(medocType => {
                let eTpe = action.payload.medocTypes.find(eTpe => eTpe.id === medocType.id);
                if(eTpe) {
                    _medocTypes.push(eTpe);
                } else {
                    _medocTypes.push(medocType);
                }
            })
            nexState = {
                ...state,
                medocTypes: [..._medocTypes]
            }
            return nexState || state;
        case ActionTypes.REMOVE_MEDOC_TYPE:
            nexState = {
                ...state,
                medocTypes: [...state.medocTypes.filter(medocType => action.payload.medocTypes.findIndex(eTpe => eTpe.id === medocType.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}