import BillableItemType from "../../../utils/enums/BillableItemType";
import { Validable, ValidationResult } from "../../../utils/Validable";
import BillableItem from "../../accounting/model/BillableItem";
import OperationType from "./OperationType";

export default class Operation extends BillableItem implements Validable {
	public operationType: OperationType;

	constructor(name: string, operationType: OperationType, version: number = 1) {
		super(name, BillableItemType.OPERATION, version);
		this.operationType = operationType;
	}
	
	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			vr = super.validate(fieldName);
		}
		else if(fieldName === "operationType") {
			if(!this.operationType || !this.operationType.validateAll().isValid) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("name").isValid || !this.validate("operationType").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}

		return vr;
	}

	clone() {
		let operation: Operation = new Operation(this.name, this.operationType.clone(), this.version);
		operation.account = this.account? this.account.clone() : undefined;
		operation.description = this.description;
		operation.id = this.id;
		return operation;
	}
	
	get displayName() {
		return this.name || 'New surgery'
	}
}
