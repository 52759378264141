import Ward from "../../app/common/model/Ward";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface WardState {
    wards: Array<Ward>;
}

const initialState: WardState = {
    wards: []
}

export default function wardReducer(state = initialState, action: Action<WardState>) {
    let nexState: WardState;
    switch (action.type) {
        case ActionTypes.ADD_WARDS:
            nexState = {
                ...state,
                wards: merge(state.wards, action.payload.wards, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_WARDS:
            let _wards: Array<Ward> = [];
            state.wards.forEach(ward => {
                let w = action.payload.wards.find(w => w.id === ward.id);
                if(w) {
                    _wards.push(w);
                } else {
                    _wards.push(ward);
                }
            })
            nexState = {
                ...state,
                wards: [..._wards]
            }
            return nexState || state;
        case ActionTypes.REMOVE_WARDS:
            nexState = {
                ...state,
                wards: [...state.wards.filter(ward => action.payload.wards.findIndex(w => w.id === ward.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}