import ExpenseStatus from "../../../utils/enums/ExpenseStatus";
import UserView from "../../admin/model/UserView";
import ExpenseRequest from "./ExpenseRequest";

export default class Expense {
    public id!: number;
    public version!: number;
    public expenseDate!: Date;
    public amount!: number;
    public employee!: UserView;
    public description!: string;
    public status!: ExpenseStatus;
    public attachedReceipt?: string;
    public paidByEmployee!: boolean;

    toExpenseRequest() {
        let output = new ExpenseRequest();
        output.amount = this.amount;
        output.description = this.description;
        output.expenseDate = this.expenseDate;
        output.paidByEmployee = this.paidByEmployee;
        return output;
    }
}