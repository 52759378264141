import Lot from "../../pharmacy/model/Lot";

export default class LotInfoView {
	public id?: number;
	public version: number;
	public lot: Lot;
	public quantity: number;	
	public remainingQty: number;

	constructor(lot: Lot, qty: number, remainingQty: number, version: number = 1) {
		this.lot = lot;
		this.quantity = qty;
		this.remainingQty = remainingQty;
		this.version = version;
	}

}
