import Role from "./Role";
import UserView from "./UserView";

export default class UserRole {
	public id?: number;	
	public version: number;
	public user: UserView;
	public role: Role;

	constructor(user: UserView, role: Role, version: number = 1) {
		this.user = user;
		this.role = role;
		this.version = version;
	}
}
