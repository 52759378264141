import CommonMappers from "../common/CommonMappers";
import SimpleVisit from "./model/SimpleVisit";
import Visit from "./model/Visit";
import VisitClosing from "./model/VisitClosing";

const HealthCareMappers = {
    mapVisit: function(data: any) {
        if(!data) return undefined;
        let patient = CommonMappers.mapPatient(data.patient);
        if(!patient) return undefined;

        let visit = new Visit(patient, data.visitType, data.version);
		visit.id = data.id;
		visit.refCode = data.refCode;
		visit.visitDate = data.visitDate? new Date(data.visitDate) : undefined;
		visit.closed = data.closed;
        visit.closeDate = data.closeDate? new Date(data.closeDate) : undefined;
		return visit;
    },
    mapSimpleVisit: function(data: any) {
        if(!data) return undefined;
        let visit = new SimpleVisit(data.id, data.refCode, new Date(data.visitDate), data.closed, data.visitType, data.version);
        visit.closeDate = data.closeDate? new Date(data.closeDate) : undefined;
		return visit;
    },
    mapVisitClosing: function(data: any) {
        if(!data) return undefined;
        let patient = CommonMappers.mapPatient(data.patient);
        if(!patient) return undefined;
        return new VisitClosing(
            data.id,
            data.refCode, 
            new Date(data.visitDate),
            patient,
            data.visitType,
            data.balance,
            data.openedBills,
            data.version
        );
    }
}

export default HealthCareMappers;