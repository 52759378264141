import { isPhoneValid, Validable, ValidationResult } from "../../../utils/Validable";
import Ward from "../../common/model/Ward";

export default class User implements Validable{
	public id?: number;	
	public version: number;
	public login: string;	
	public password: string;	
	public enabled: boolean;	
	public lastName: string;	
	public ward?: Ward;
	public firstName?: string;
	public address?: string;
	public phone?: string;
	public profileImage?: string;
	public profession?: string;
	public bannerFilename?: string;
	public lastConnectionDate?: Date;

	constructor(login: string, password: string, lastName: string, enabled: boolean = false, version: number = 1) {
		this.login = login;
		this.password = password;
		this.lastName = lastName;
		this.enabled = enabled;
		this.version = version;
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("login").isValid || !this.validate("password").isValid || !this.validate("lastName").isValid) {
			vr = {
				...vr,
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		if(this.phone && !isPhoneValid(this.phone)){
			vr = {
				...vr,
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "login") {
			if(!this.login || !this.login.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "password") {
			if(!this.password || !this.password.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "lastName") {
			if(!this.lastName || !this.lastName.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	clone() {
		let user: User = new User(this.login, this.password, this.lastName, this.enabled, this.version);
		user.id = this.id;
		user.firstName = this.firstName;
		user.address = this.address;
		user.phone = this.phone;
		user.ward = this.ward;
		user.profileImage = this.profileImage;
		user.profession = this.profession;
		user.bannerFilename = this.bannerFilename;
		user.lastConnectionDate = this.lastConnectionDate;
		return user;
	}

	fullName() {
		return this.firstName? this.lastName + " " + this.firstName : this.lastName; 
	}
}
