import BillableItemType from "../../../utils/enums/BillableItemType";
import { Validable, ValidationResult } from "../../../utils/Validable";
import BillableItem from "../../accounting/model/BillableItem";
import ExamType from "./ExamType";

export default class Exam extends BillableItem implements Validable {
	public examType: ExamType;

	constructor(name: string, examType: ExamType, version: number = 1) {
		super(name, BillableItemType.EXAM, version);
		this.examType = examType;
	}
	
	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			vr = super.validate(fieldName);
		}
		else if(fieldName === "examType") {
			if(!this.examType || !this.examType.validateAll().isValid) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("name").isValid || !this.validate("examType").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}

		return vr;
	}

	clone() {
		let exam: Exam = new Exam(this.name, this.examType.clone(), this.version);
		exam.account = this.account? this.account.clone() : undefined;
		exam.description = this.description;
		exam.id = this.id;
		return exam;
	}

	get displayName() {
		return this.name || 'New exam'
	}
}
