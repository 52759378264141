import moment from "moment";
import Constants from "../../../utils/Constants";
import OrderStatus from "../../../utils/enums/OrderStatus";
import User from "../../admin/model/User";
import Supplier from "../../common/model/Supplier";

export default class Order {
	public id?: number;	
	public version: number;
	public issuer: User;
	public supplier: Supplier;
	public orderDate?: Date;
	public deliveryDate?: Date;	
	public orderStatus?: OrderStatus;

	constructor(issuer: User, supplier: Supplier, version: number = 1) {
		this.issuer = issuer;
		this.supplier = supplier;
		this.version = version;
	}

	clone() {
		let order = new Order(this.issuer.clone(), this.supplier.clone(), this.version);
		order.id = this.id;
		order.orderDate = this.orderDate;
		order.deliveryDate = this.deliveryDate;
		order.orderStatus = this.orderStatus;
		return order;
	}
	
	getBadgeType() {
        switch(this.orderStatus) {
            case OrderStatus.PREPARING:
                return 'bg-secondary';
            case OrderStatus.DELIVERED:
                return 'bg-success';
            default: // OrderStatus.CANCELLED
                return 'bg-danger';
        }
    }

	get displayName() {
		return [this.supplier.name, moment(this.orderDate).format(Constants.DATE_FORMAT.DATE_ONLY)].join(" | ")
	}
}
