import { formatNumber } from "../../../utils/Utils";
import Ward from "../../common/model/Ward";
import BillableItem from "./BillableItem";
import LotInfoView from "./LotInfoView";
import Ordonnance from "./Ordonnance";
import OrdonnanceItem from "./OrdonnanceItem";
import OrdonnanceItemCreate from "./OrdonnanceItemCreate";

export default class OrdonnanceItemView {
	public id?: number;	
	public version: number;
	public ordonnance: Ordonnance;	
	public billableItem: BillableItem;	
	public quantity: number;	
	public remainingQty?: number;	
	public unitPrice?: number;
	public discountRate?: number;	
	public billed: boolean;
	public collectedAmount: number;
	public lotInfos?: Array<LotInfoView>;
	public ward?: Ward;
	public numberPerTake?: number;
	public frequencyInDay?: number;
	public frequencyInPeriod?: number;

	constructor(ordonnance: Ordonnance, billableItem: BillableItem, quantity: number, billed = false, version: number = 1) {
		this.ordonnance = ordonnance;
		this.billableItem = billableItem;
		this.quantity = quantity;
		this.billed = billed;
		this.version = version;
		this.collectedAmount = 0;
	}

	clone() {
		let ordonnanceItem = new OrdonnanceItemView(this.ordonnance, this.billableItem, this.quantity, this.billed, this.version);
		ordonnanceItem.id = this.id;
		ordonnanceItem.remainingQty = this.remainingQty;
		ordonnanceItem.unitPrice = this.unitPrice;
		ordonnanceItem.discountRate = this.discountRate;
		ordonnanceItem.collectedAmount = this.collectedAmount;
		ordonnanceItem.lotInfos = this.lotInfos? [...this.lotInfos] : [];
		ordonnanceItem.ward = this.ward;
		ordonnanceItem.numberPerTake = this.numberPerTake;
		ordonnanceItem.frequencyInDay = this.frequencyInDay;
		ordonnanceItem.frequencyInPeriod = this.frequencyInPeriod;
		return ordonnanceItem; 
	}
	
	getMetaData(useInitialQty = false): Map<string, string> {
		let metaData = new Map<string, string>();
		const qty = useInitialQty? this.quantity.toString() : this.getQty().toString();
		metaData.set("Quantity", qty);
		if(this.numberPerTake !== undefined && this.numberPerTake !== null) {
			metaData.set("Qty per take", this.numberPerTake.toString());
		}
		if(this.frequencyInDay !== undefined && this.frequencyInDay !== null) {
			metaData.set("Frequency (day)", this.frequencyInDay.toString());
		}
		if(this.frequencyInPeriod !== undefined && this.frequencyInPeriod !== null) {
			if(this.frequencyInPeriod === 1) {
				metaData.set("Everyday", "");
			} else {
				metaData.set("Frequency (period)", this.frequencyInPeriod.toString());
			}
		}
		return metaData;
	}

	toOrdonanceItemCreate() {
		let output = new OrdonnanceItemCreate(this.billableItem, this.quantity, this.version);
		output.frequencyInDay = this.frequencyInDay;
		output.frequencyInPeriod = this.frequencyInPeriod;
		output.numberPerTake = this.numberPerTake;
		output.ward = this.ward;
		output.id = this.id;
		return output;
	}

	toOrdonanceItem() {
		let ordonnanceItem = new OrdonnanceItem(this.ordonnance, this.billableItem, this.quantity, this.billed, this.version);
		ordonnanceItem.id = this.id;
		ordonnanceItem.unitPrice = this.unitPrice;
		ordonnanceItem.discountRate = this.discountRate;
		ordonnanceItem.lotInfos = this.lotInfos? [...this.lotInfos] : [];
		ordonnanceItem.ward = this.ward;
		ordonnanceItem.numberPerTake = this.numberPerTake;
		ordonnanceItem.frequencyInDay = this.frequencyInDay;
		ordonnanceItem.frequencyInPeriod = this.frequencyInPeriod;
		return ordonnanceItem;
	}

	getTotalPriceAsString() {
		if(this.unitPrice === undefined) return '';
		const discountRate = this.discountRate || 0;
		
		let totalPrice = this.getQty() * this.unitPrice;
		const discount = totalPrice * discountRate / 100;
		totalPrice = totalPrice - discount;
		return formatNumber(totalPrice);
	}

	getTotalPrice() {
		if(this.unitPrice === undefined) return 0;
		const discountRate = this.discountRate || 0;
		let totalPrice = this.getQty() * this.unitPrice;
		const discount = totalPrice * discountRate / 100;
		return totalPrice - discount;
	}

	getTotalInitialPrice() {
		if(this.unitPrice === undefined) return 0;
		const discountRate = this.discountRate || 0;
		let totalPrice = this.quantity * this.unitPrice;
		const discount = totalPrice * discountRate / 100;
		return totalPrice - discount;
	}

	getQty() {
		return (this.remainingQty !== undefined && this.remainingQty !== null)? this.remainingQty : this.quantity;
	}

	isPaid() {
		return this.getTotalPrice() === this.collectedAmount;	
	}
}
