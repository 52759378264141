import BillableItemType from "../../../utils/enums/BillableItemType";
import { Validable, ValidationResult } from "../../../utils/Validable";
import BillableItem from "../../accounting/model/BillableItem";
import MedocType from "./MedocType";
import MedocUnit from "./MedocUnit";

export default class Medoc extends BillableItem implements Validable {
	public code?: string;	
	public medocType: MedocType;	
	public minQuantity?: number;
	public packaging?: string;
	public shape?: string;	
	public unit: MedocUnit;
	public outdatedQuantity?: number;
	public availableQuantity?: number;

	constructor(name: string, medocType: MedocType, unit: MedocUnit, version: number = 1) {
		super(name, BillableItemType.MEDOC, version);
		this.medocType = medocType;
		this.unit = unit;
	}
	
	validate(fieldName: string, automaticDrugCodes=true): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "code" && !automaticDrugCodes) {
			if(!this.code) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		if(fieldName === "name") {
			vr = super.validate(fieldName);
		}
		else if(fieldName === "medocType") {
			if(!this.medocType || !this.medocType.validateAll().isValid) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "unit") {
			if(!this.unit || !this.unit.validateAll().isValid) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(automaticDrugCodes=true): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("code", automaticDrugCodes).isValid ||
			!this.validate("name").isValid ||
			!this.validate("medocType").isValid ||
			!this.validate("unit").isValid) 
		{
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}

		return vr;
	}

	clone() {
		let medoc: Medoc = new Medoc(this.name, this.medocType.clone(), this.unit.clone(), this.version);
		medoc.account = this.account? this.account.clone() : undefined;
		medoc.description = this.description;
		medoc.id = this.id;
		medoc.code = this.code;
		medoc.minQuantity = this.minQuantity;
		medoc.packaging = this.packaging;
		medoc.shape = this.shape;
		medoc.outdatedQuantity = this.outdatedQuantity;
		medoc.availableQuantity = this.availableQuantity;
		return medoc;
	}

	get fullname() {
		return `${this.name} (${this.unit.name})`
	}

	get displayName() {
		return this.name || 'New medicine'
	}
}
