import Account from './model/Account';
import BillableItem from './model/BillableItem';
import PriceList from './model/PriceList';
import DiscountPlan from './model/DiscountPlan';
import DiscountRate from './model/DiscountRate';
import PriceItem from './model/PriceItem';
import Ordonnance from './model/Ordonnance';
import HealthCareMappers from '../healthcare/HealthCareMappers';
import AdminMappers from '../admin/AdminMappers';
import PharmacyMappers from '../pharmacy/PharmacyMappers';
import OrdonnanceItemView from './model/OrdonnanceItemView';
import CommonMappers from '../common/CommonMappers';
import OrdonnanceSummary from './model/OrdonnanceSummary';
import LotInfoView from './model/LotInfoView';
import ServiceInfo from './model/ServiceInfo';
import Payment from './model/Payment';
import RefundItem from './model/RefundItem';
import OrdonnanceItem from './model/OrdonnanceItem';
import Refund from './model/Refund';
import ArrayMapper from '../../utils/ArrayMapper';
import BillPaymentItem from './model/BillPaymentItem';
import Expense from './model/Expense';

const AccountingMappers = {
    mapAccount: function(data: any): Account | undefined {
		if(!data) return undefined;
		let account: Account = new Account(data.name, data.accountNumber, data.version);
		account.id = data.id;
		account.description = data.description;
		return account;
	},
	mapBillableItem: function(data: any): BillableItem | undefined {
		if(!data) return undefined;
		let bItem: BillableItem = new BillableItem(data.name, data.billableItemtype, data.version);
		bItem.id = data.id;
		bItem.description = data.description;
		bItem.account = this.mapAccount(data.account);
		return bItem;
	},
	mapPriceList: function(data: any): PriceList | undefined {
		if(!data) return undefined;
		let pl = new PriceList(data.name, data.currency, data.defaultOne, data.version);
		pl.id = data.id;
		pl.description = data.description;
		return pl;
	},
	mapDiscountPlan: function(data: any): DiscountPlan | undefined {
		if(!data) return undefined;
		let dp = new DiscountPlan(data.name, data.examRate, data.medocRate, data.operationRate, data.otherRate, data.version);
		dp.id = data.id;
		dp.description = data.description;
		return dp;
	},
    mapDiscountRate: function(data: any): DiscountRate | undefined {
		if(!data) return undefined;
		let dPlan = this.mapDiscountPlan(data.discountPlan);
		let bItem = this.mapBillableItem(data.billableItem);
		if(!dPlan || !bItem) return undefined;
		let dr = new DiscountRate(dPlan, bItem, data.rate, data.version);
		dr.id = data.id;
		return dr;
	},
	mapPriceItem: function(data: any): PriceItem | undefined {
		if(!data) return undefined;
		let pl = this.mapPriceList(data.priceList);
		let bItem = this.mapBillableItem(data.billableItem);
		if(!pl || !bItem) return undefined;
		let pi = new PriceItem(pl, bItem, data.price, data.version);
		pi.id = data.id;
		return pi;
	},
	mapLotInfoView(data: any): LotInfoView | undefined {
		if(!data) return undefined;
		let lot = PharmacyMappers.mapLot(data.lot);
		if(!lot) return undefined;
		let lotInfo = new LotInfoView(lot, data.quantity, data.remainingQty, data.version);
		lotInfo.id = data.id;
		return lotInfo
	},
	mapOrdonnance: function(data: any): Ordonnance | undefined {
		if(!data) return undefined;
		let visit = HealthCareMappers.mapVisit(data.visit);
		let prescriber = AdminMappers.mapUserView(data.prescriber);
		if(!visit || !prescriber) return undefined;
		let ord = new Ordonnance(data.refCode, visit, new Date(data.prescriptionDate), prescriber, data.status, data.version);
		ord.id = data.id;
		ord.note = data.note;
		ord.currency = data.currency;
		return ord;
	},
	mapOrdonnanceSummary: function(data: any): OrdonnanceSummary | undefined {
		if(!data) return undefined;
		let visit = HealthCareMappers.mapVisit(data.visit);
		let prescriber = AdminMappers.mapUserView(data.prescriber);
		if(!visit || !prescriber) return undefined;
		let ord = new OrdonnanceSummary(data.refCode, visit, new Date(data.prescriptionDate), prescriber, data.status, data.version);
		ord.id = data.id;
		ord.note = data.note;
		ord.currency = data.currency
		ord.initialAmount = data.initialAmount;
		ord.currentAmount = data.currentAmount;
		ord.balance = data.balance;
		return ord;
	},
	mapOrdonnanceItemView(data: any): OrdonnanceItemView | undefined {
		if(!data) return undefined;
		let ordonnance = this.mapOrdonnance(data.ordonnance);
		let billableItem = this.mapBillableItem(data.billableItem);
		if(!ordonnance || !billableItem) return undefined;

		let ordonnanceItem = new OrdonnanceItemView(ordonnance, billableItem, data.quantity, data.billed, data.version);
		ordonnanceItem.id = data.id;
		ordonnanceItem.remainingQty = data.remainingQty;
		ordonnanceItem.unitPrice = data.unitPrice;
		ordonnanceItem.discountRate = data.discountRate;
		ordonnanceItem.collectedAmount = data.collectedAmount;
		ordonnanceItem.lotInfos = data.lotInfos? data.lotInfos.map((li: any) => this.mapLotInfoView(li)) : [];
		ordonnanceItem.ward = CommonMappers.mapWard(data.ward);
		ordonnanceItem.numberPerTake = data.numberPerTake;
		ordonnanceItem.frequencyInDay = data.frequencyInDay;
		ordonnanceItem.frequencyInPeriod = data.frequencyInPeriod;
		return ordonnanceItem;
	},
	mapOrdonnanceItem(data: any): OrdonnanceItem | undefined {
		if(!data) return undefined;
		let ordonnance = this.mapOrdonnance(data.ordonnance);
		let billableItem = this.mapBillableItem(data.billableItem);
		if(!ordonnance || !billableItem) return undefined;

		let ordonnanceItem = new OrdonnanceItem(ordonnance, billableItem, data.quantity, data.billed, data.version);
		ordonnanceItem.id = data.id;
		ordonnanceItem.unitPrice = data.unitPrice;
		ordonnanceItem.discountRate = data.discountRate;
		ordonnanceItem.lotInfos = data.lotInfos? data.lotInfos.map((li: any) => this.mapLotInfoView(li)) : [];
		ordonnanceItem.ward = CommonMappers.mapWard(data.ward);
		ordonnanceItem.numberPerTake = data.numberPerTake;
		ordonnanceItem.frequencyInDay = data.frequencyInDay;
		ordonnanceItem.frequencyInPeriod = data.frequencyInPeriod;
		return ordonnanceItem;
	},
	mapServiceInfo(data: any): ServiceInfo | undefined {
		if(!data) return undefined;
		return new ServiceInfo(data.price, data.discountRate, data.remainingQuantity);
	},
	mapBillPaymentItem(data: any): BillPaymentItem | undefined {
		if(!data) return undefined;
		let ordonnanceItem = this.mapOrdonnanceItemView(data.ordonnanceItem);
		if(!ordonnanceItem) return undefined;

		let paymentItem = new BillPaymentItem(ordonnanceItem, data.collectedAmount, data.version);
		paymentItem.id = data.id;
		return paymentItem;
	},
	mapPayment(data: any): Payment | undefined {
		if(!data) return undefined;
		let ordonnance = this.mapOrdonnance(data.ordonnance);
		let cashier = AdminMappers.mapUserView(data.cashier);
		if(!ordonnance || !cashier) return undefined;

		let payment = new Payment(ordonnance, data.amount, cashier, data.version);
		payment.paymentDate = data.paymentDate? new Date(data.paymentDate) : undefined;
		payment.refCode = data.refCode;
		payment.id = data.id;
		payment.details = data.details? ArrayMapper.map<any, BillPaymentItem>(it => this.mapBillPaymentItem(it), data.details) : [];
		return payment;
	},
	mapRefundItem(data: any): RefundItem | undefined {
		if(!data) return undefined;
		let ordonnanceItem = this.mapOrdonnanceItemView(data.ordonnanceItem);
		let lot = PharmacyMappers.mapLot(data.lot);
		if(!ordonnanceItem) return undefined;

		let refundItem = new RefundItem(ordonnanceItem, data.refundedQuantity, lot, data.version);
		refundItem.id = data.id;
		return refundItem;
	},
	mapRefund(data: any): Refund | undefined {
		if(!data) return undefined;
		let ordonnance = this.mapOrdonnance(data.ordonnance);
		let cashier = AdminMappers.mapUserView(data.cashier);
		if(!ordonnance || !cashier) return undefined;

		let refund = new Refund(ordonnance, data.amount, cashier, data.reason, data.version);
		refund.id = data.id;
		refund.refCode = data.refCode;
		refund.refundDate = data.refundDate? new Date(data.refundDate) : undefined;
		refund.type = data.type;
		refund.details = data.details? ArrayMapper.map<any, RefundItem>(it => this.mapRefundItem(it), data.details) : [];
		return refund;
	},
	mapExpense(data: any): Expense | undefined {
		if(!data) return undefined;
		let employee = AdminMappers.mapUserView(data.employee);
		if(!employee) return undefined;

		let expense = new Expense();
		expense.id = data.id;
		expense.version = data.version;
		expense.expenseDate = new Date(data.expenseDate);
		expense.amount = data.amount;
		expense.employee = employee;
		expense.description = data.description;
		expense.status = data.status;
		expense.attachedReceipt = data.attachedReceipt;
		expense.paidByEmployee = data.paidByEmployee;
		return expense;
	}
}

export default AccountingMappers;