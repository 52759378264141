const translation_en = { 
    translation: {
        "App under dev": "XPharma app under development",
        "Welcome": "Welcome",
        "User name": "User name",
        "Password": "Password",
        "Log In": "Log In",
        "Remember me": "Remember me",
        "Home": "Home",
        "Hospital": "Hospital",
        "Cashier": "Cashier",
        "Pharmacy": "Pharmacy",
        "Store": "Store",
        "Parameters": "Parameters",
        "en": "English",
        "fr": "Français",
        "Log out": "Log out",
        "HOSPITAL PHARMACY MANAGEMENT": "HOSPITAL PHARMACY MANAGEMENT",
        "New": "New",
        "Medicine": "Medicine",
        "Patient": "Patient",
        "No result to show": "No result to show",
        "Invalid email": "invalid email",
        "Invalid phone number": "phone number must have 9 digits starting with 6 or 2",
        "Mandatory field": "This field is mandatory",
        "errors in form": "There are errors in the form",
        "Unknown error": "Unknown error",
        "Reports": "Reports",
        "Operation succeeded": "Operation succeeded!",
        "Visit": "Visit",
        "Ordonnance": "Ordonnance",
        "The date of preparation must be before the expiration date": "The date of preparation must be before the expiration date",
        "Administration": "Admin",
        "Patients": "Patients",
        "Accounting": "Accounting",
        "Cash register": "Cash Box",
        "Password mismatch": "Password mismatch",
        "user": "User",
        "recorder": "Recorder",
        "doctor": "Doctor",
        "cashier": "Cashier",
        "accountant": "Accountant",
        "pharmacist": "Pharmacist",
        "stock_manager": "Stock manager",
        "admin": "Administrator",
        "app_admin": "App Administrator",
        "no": "no",
        "yes": "yes",
        "Invalid quantity": "Invalid quantity",
        "Medicines": "Medicines",
        "Orders": "Orders",
        "Requisition requests": "Requisition requests",
        "Stock control": "Stock control",
        "Types of medicines": "Types of medicines",
        "Units": "Units",
        "Required operation pending. Try later": "Required operation pending. Try later",
        "Prescriptions": "Prescriptions",
        "Error: please check your connection": "Error: please check your connection",
        "Hospital pharmacy management": "Hospital pharmacy management",
        "Wards": "Wards",
        "Hospital Services": "Hospital Services",
        "Operations": "Surgeries",
        "Exams": "Exams",
        "Operations types": "Surgery types",
        "Exams types": "Exams types",
        "Suppliers": "Suppliers",
        "My Profile": "My Profile",
        "Colleagues": "Colleagues",
        "Users": "Users",
        "Roles": "Roles",
        "Closing of visits": "Closing of visits",
        "Reopening bills": "Reopening bills",
        "Settings": "Settings",
        "Application's version": "Application's version",
        "Build ID": "Build ID",
        "Prices lists": "Prices lists",
        "Discount plans": "Discount plans",
        "Accounts": "Accounts",
        "Welcome to the XPharma Setup Wizard": "Welcome to the XPharma Setup Wizard",
        "Please complete the installation so that your application is ready for use": "Please complete the installation so that your application is ready for use",
        "Make sure you have deployed the server correctly": "Make sure you have deployed the server correctly",
        "The server is correctly deployed and working": "The server is correctly deployed and working",
        "The web client is properly configured": "The web client is properly configured",
        "Installation Wizard": "Installation Wizard",
        "Prerequisites": "Prerequisites",
        "Hospital settings": "Hospital settings",
        "Admin account": "Admin account",
        "Installation completed": "Installation completed",
        "Back": "Back",
        "Install": "Install",
        "Return to the login page": "Return to the login page",
        "Next": "Next",
        "Cancel": "Cancel",
        "Basic information": "Basic information",
        "Hospital name": "Hospital name",
        "City": "City",
        "Health area": "Health area",
        "Contact": "Contact",
        "Address": "Address",
        "Phone": "Phone",
        "E-mail": "E-mail",
        "P.O. Box": "P.O. Box",
        "Congratulations": "Congratulations",
        "You have completed all the steps": "You have completed all the steps",
        "Your application has been successfully installed": "Your application has been successfully installed",
        "Go back to the login page to take advantage of it now": "Go back to the login page to take advantage of it now",
        "Access parameters": "Access parameters",
        "Personal information": "Personal information",
        "Last name": "Last name",
        "First name": "First name",
        "You are connected since": "You are connected since",
        "Search": "Search",
        "Loading": "Loading",
        "Edit": "Edit",
        "Delete": "Delete",
        "No item found": "No item found",
        "Invalid city name": "Invalid city name",
        "New city": "New city",
        "Confirm the selection": "Confirm the selection",
        "No item selected": "No item selected",
        "Create new": "Create new",
        "No patient found": "No patient found",
        "New patient": "New patient",
        "No medicine found": "No medicine found",
        "New lot": "New lot",
        "Unable to start the camera": "Unable to start the camera",
        "Invalid file type": "Invalid file type",
        "Drag and drop image here or use one of the following options": "Drag and drop image here or use one of the following options",
        "Capture": "Capture",
        "Browse": "Browse",
        "Quit": "Quit",
        "No file loaded": "No file loaded",
        "of": "of",
        "Displays": "Displays",
        "items": "items",
        "Previous": "Previous",
        "Warning": "Warning",
        "No item available": "No item available",
        "more(s)": "more(s)",
        "Save": "Save",
        "Lot code": "Lot code",
        "Preparation date": "Preparation date",
        "Expiration date": "Expiration date",
        "Manufacturer": "Manufacturer",
        "Number of packets": "Number of packets",
        "Quantity per packet": "Quantity per packet",
        "Discount": "Discount",
        "Details": "Details",
        "Clear": "Clear",
        "Filter": "Filter",
        "No available data to display": "No available data to display",
        "Inventories": "Inventories",
        "Requisitions": "Requisitions",
        "Internal use": "Internal use",
        "Close": "Close",
        "No pharmacy found": "No pharmacy found",
        "No description provided": "No description provided",
        "Open": "Open",
        "Lot": "Lot",
        "Initial quantity": "Initial quantity",
        "Critical quantity": "Critical quantity",
        "In stock": "In stock",
        "Purchase price": "Purchase price",
        "Critical stock reached": "Critical stock reached",
        "Expired medicine": "Expired medicine",
        "From": "From",
        "To": "To",
        "to": "to",
        "Expiration": "Expiration",
        "Requisition": "Requisition",
        "Suppression in progress": "Suppression in progress",
        "Cancellation in progress": "Cancellation in progress",
        "Reopening the requisition": "Reopening the requisition",
        "Reopening the order": "Reopening the order",
        "Rejecting the requisition": "Rejecting the requisition",
        "Proceed to approval": "Proceed to approval",
        "Reject": "Reject",
        "Reopen": "Reopen",
        "Source": "Source",
        "Choose": "Choose",
        "Outgoing requisition requests": "Outgoing requisition requests",
        "Incoming requisition requests": "Incoming requisition requests",
        "Target": "Target",
        "Status": "Status",
        "All statuses": "All statuses",
        "APPROVED": "Approved",
        "CANCELLED": "Cancelled",
        "WAITING": "Waiting",
        "REJECTED": "Rejected",
        "Order status": "Order status",
        "Order date": "Order date",
        "Requisition status": "Requisition status",
        "Requisition date": "Requisition date",
        "No target ward selected": "No target ward selected",
        "Unable to save the request": "Unable to save the request",
        "Unable to approve the request": "Unable to approve the request",
        "One or more invalid requisitioned quantities": "One or more invalid requisitioned quantities",
        "Invalid medicine or quantity": "Invalid medicine or quantity",
        "The medicine is already listed": "The medicine is already listed",
        "Failed to prepare the item": "Failed to prepare the item",
        "Initiated by": "Initiated by",
        "Initiated on": "Initiated on",
        "New item": "New item",
        "Select": "Select",
        "Quantity": "Quantity",
        "Available": "Available",
        "Add": "Add",
        "Do you really want to delete this item?": "Do you really want to delete this item?",
        "Requested": "Requested",
        "Requisitioned": "Requisitioned",
        "Invalid value": "Invalid value",
        "Save request": "Save request",
        "Order": "Order",
        "PREPARING": "Preparing",
        "DELIVERED": "Delivered",
        "Delivering": "Delivering",
        "Ordered quantity": "Ordered quantity",
        "Qty": "Qty",
        "Save order": "Save order",
        "One or more missing lots": "One or more missing lots",
        "Received quantity": "Received quantity",
        "Unable to create the order, please check the supplier": "Unable to create the order, please check the supplier",
        "Order details": "Order details",
        "Supplier": "Supplier",
        "Delivered on": "Delivered on",
        "Name": "Name",
        "Description": "Description",
        "Code": "Code",
        "available(s)": "available",
        "expired": "expired",
        "Type of medicine": "Type of medicine",
        "Undefined": "Undefined",
        "Unit": "Unit",
        "Packaging": "Packaging",
        "Shape": "Shape",
        "Account": "Account",
        "Medicine's name": "Medicine's name",
        "All types": "All types",
        "Comment": "Comment",
        "List of consumed items": "List of consumed items",
        "Date": "Date",
        "Confirm": "Confirm",
        "Please confirm the internal consumption of": "Please confirm the internal consumption of",
        "PENDING": "Pending",
        "Reopening the inventory": "Reopening the inventory",
        "Validating the inventory": "Validating the inventory",
        "Inventory status": "Inventory status",
        "Creation date": "Creation date",
        "General": "General",
        "Items": "Items",
        "Add items to the inventory in the Items tab": "Add items to the inventory in the Items tab",
        "Reference": "Reference",
        "Closed by": "Closed by",
        "Closing date": "Closing date",
        "Known Qty": "Known Qty",
        "Actual Qty": "Actual Qty",
        "There is nothing to save": "Il n'y a rien à enregistrer",
        "One or more incorrect entries": "One or more incorrect entries",
        "Duplicated item in the inventory": "Duplicated item in the inventory",
        "Duplicated lot code": "Duplicated lot code",
        "Delete selection": "Delete selection",
        "Cancel selection": "Cancel selection",
        "New Entry": "New Entry",
        "Total price": "Total price",
        "No meta data available": "No meta data available",
        "Approve": "Approve",
        "Main": "Main",
        "Ward with pharmacy": "Ward with pharmacy",
        "Ward without pharmacy": "Ward without pharmacy",
        "Dispensing pharmacy": "Dispensing pharmacy",
        "Sex": "Gender",
        "Male": "Male",
        "Female": "Female",
        "Visit closed": "Visit closed",
        "Visit pending": "Visit pending",
        "There is currently no ongoing visit": "There is currently no ongoing visit",
        "There is actually an ongoing visit": "There is actually an ongoing visit",
        "New visit": "New visit",
        "Born in": "Born in",
        "Born on": "Born on",
        "Closing": "Closing",
        "Address and contacts": "Address and contacts",
        "Medical information": "Medical information",
        "Single": "Single",
        "Married": "Married",
        "In cohabitation": "In cohabitation",
        "Divorced": "Divorced",
        "Widow": "Widow",
        "Date of birth": "Date of birth",
        "Place of birth": "Place of birth",
        "Marital status": "Marital status",
        "Number of children": "Number of children",
        "Occupation": "Occupation",
        "City of residence": "City of residence",
        "Within the area": "Within the area",
        "Who to contact": "Who to contact",
        "Address of the contact person": "Address of the contact person",
        "Phone number of the contact person": "Phone number of the contact person",
        "Blood type": "Blood type",
        "Notes": "Notes",
        "Delete the picture": "Delete the picture",
        "Visits": "Visits",
        "Closed visits": "Closed visits",
        "Ongoing visits": "Ongoing visits",
        "Please close the current visit": "Please close the current visit",
        "Do you really want to delete": "Do you really want to delete",
        "this patient": "this patient",
        "this visit": "this visit",
        "This action is destructive and irreversible": "This action is destructive and irreversible",
        "You have cancelled the operation": "You have cancelled the operation",
        "No visits found": "No visits found",
        "Visit date": "Visit date",
        "Visit type": "Visit type",
        "Pharmacy (buying medicines)": "Pharmacy (buying medicines)",
        "Appointment": "Appointment",
        "Consultation": "Consultation",
        "Emergency": "Emergency",
        "Duplicated item": "Duplicated item",
        "Unable to perform the operation, please try again later": "Unable to perform the operation, please try again later",
        "No patient selected": "No patient selected",
        "No items to be saved": "No items to be saved",
        "Error occured": "Error occured",
        "Qty per take": "Qty per take",
        "Frequency (day)": "Frequency (day)",
        "Frequency (period)": "Frequency (period)",
        "Prescriber": "Prescriber",
        "Other services": "Other services",
        "Prescribed items": "Prescribed items",
        "Everyday": "Everyday",
        "Date of prescription": "Date of prescription",
        "Operation type": "Type of surgery",
        "Exam type": "Exam type",
        "Are you sure you want to": "Are you sure you want to",
        "deactivate": "deactivate",
        "Deactivate": "Deactivate",
        "activate": "activer",
        "Activate": "Activer",
        "delete": "supprimer",
        "this user": "this user",
        "Reset password": "Reset password",
        "Reallocate": "Reallocate",
        "New user": "New user",
        "Username": "Username",
        "Confirm password": "Confirm password",
        "Ward": "Ward",
        "Full name": "Full name",
        "Last connection": "Last connection",
        "ENABLED": "ENABLED",
        "DISABLED": "DISABLED",
        "No user found": "No user found",
        "Password reset": "Password reset",
        "Reallocation": "Reallocation",
        "New password": "New password",
        "Target ward": "Target ward",
        "Some visits have not been closed": "Some visits have not been closed",
        "We found no visit that can be closed": "We found no visit that can be closed",
        "selected": "selected",
        "Close2": "Close",
        "Reason": "Reason",
        "Opened bills": "Opened bills",
        "Total balance": "Total balance",
        "No pendig visit found": "No pendig visit found",
        "Please, confirm you are closing": "Please, confirm you are closing",
        "visit(s)": "visit(s)",
        "Default": "Default",
        "Value": "Value",
        "No parameter found": "No parameter found",
        "Hospital Infos": "Hospital Infos",
        "Roles and permissions": "Roles and permissions",
        "Role": "Role",
        "Manage": "Manage",
        "No role found": "No role found",
        "All users": "All users",
        "Role's users": "Role's users",
        "Change my password": "Change my password",
        "No previous login recorded": "No previous login recorded",
        "Current session": "Current session",
        "Account information": "Account information",
        "Preferences": "Preferences",
        "Default language": "Default language",
        "Color theme": "Color theme",
        "Are you sure you want to delete your profile image?": "Are you sure you want to delete your profile image?",
        "Apply": "Apply",
        "The new password must be different to the old one": "The new password must be different to the old one",
        "Change your password": "Change your password",
        "Old password": "Old password",
        "Incorrect price": "Incorrect price",
        "Service": "Service",
        "Price": "Price",
        "No price defined": "No price defined",
        "Operation": "Surgery",
        "Exam": "Exam",
        "The patient is already listed": "The patient is already listed",
        "Associated patients list": "Associated patients list",
        "Select patient": "Select patient",
        "No patient associated to this prices' list": "No patient associated to this prices' list",
        "The default prices list is applied to patients with no other prices list applied to them": "The default prices list is applied to patients with no other prices list applied to them",
        "List name": "List name",
        "Currency": "Currency",
        "Prices defined in the list": "Prices defined in the list",
        "Manage items": "Manage items",
        "Service name": "Service name",
        "Rates applied to specific elements": "Rates applied to specific elements",
        "Incorrect rate": "Incorrect rate",
        "Taux": "Taux",
        "Do you really want to delete this discount rate": "Do you really want to delete this discount rate",
        "No discount rate defined": "No discount rate defined",
        "Rate": "Rate",
        "No related patient": "No related patient",
        "Plan's name": "Plan's name",
        "Discount rates": "Discount rates",
        "Account name": "Account name",
        "Account number": "Account number",
        "Do you want to continue?": "Do you want to continue?",
        "bill": "bill",
        "No bill found": "No bill found",
        "Some bills have not been reopened": "Some bills have not been reopened",
        "We found no bill that can be reopened": "We found no bill that can be reopened",
        "No closed bill found": "No closed bill found",
        "Please confirm you want to reopen": "Please confirm you want to reopen",
        "bill(s)": "bill(s)",
        "Opened": "Opened",
        "Closed": "Closed",
        "New Bill": "New Bill",
        "Created by": "Created by",
        "Payments": "Payments",
        "Refunds": "Refunds",
        "Create": "Create",
        "You have unsaved changes, they will be discarded if you quit": "You have unsaved changes, they will be discarded if you quit",
        "Bill created on": "Bill created on",
        "Total items": "Total items",
        "Total amount": "Total amount",
        "Initial amount": "Initial amount",
        "Cash refunds": "Cash refunds",
        "Balance": "Balance",
        "Not Applicable": "Not Applicable",
        "Unable to save the payment, try again later": "Unable to save the payment, try again later",
        "Nothing to refund": "Nothing to refund",
        "Operation not permited": "Operation not permited",
        "Please enter the refund's reason": "Please enter the refund's reason",
        "Please confirm the refund": "Please confirm the refund",
        "Items selection": "Items selection",
        "New refund": "New refund",
        "Refund type": "Refund type",
        "Cash": "Cash",
        "Payment date": "Payment date",
        "Amount": "Amount",
        "Reason2": "Reason",
        "Refund reason": "Refund reason",
        "Items to be refunded": "Items to be refunded",
        "Amount to be refunded to patient": "Amount to be refunded to patient",
        "Refunds history": "Refunds history",
        "Total": "Total",
        "Unable to save the refund, try again later": "Unable to save the refund, try again later",
        "Invalid amount": "Invalid amount",
        "Please confirm the payment of an amount of": "Please confirm the payment of an amount of",
        "New payment": "New payment",
        "Collected amount": "Collected amount",
        "Refund date": "Refund date",
        "Payments history": "Payments history",
        "Please, select a patient": "Please, select a patient",
        "Please, select a pharmacy": "Please, select a pharmacy",
        "Please wait": "Please wait",
        "Item": "Item",
        "Unit price": "Unit price",
        "Something went wrong": "Something went wrong",
        "The page you requested cannot be found": "The page you requested cannot be found",
        "Please go back to application to continue": "Please go back to application to continue",
        "Go back to application": "Go back to application",
        "Ignore": "Ignore",
        "Keep me connected": "Keep me connected",
        "Your session will expire in less than 5 minutes": "Your session will expire in less than 5 minutes",
        "Your session will expire in less than 2 minutes": "Your session will expire in less than 2 minutes",
        "Your session will expire in few seconds": "Your session will expire in few seconds",
        "Your session has expired": "Your session has expired",
        "Operation in progress... please wait": "Operation in progress... please wait",
        "Print receipt": "Print receipt",
        "Download": "Download",
        "Stock sheet": "Stock sheet",
        "Print": "Print",
        "Info": "Info",
        "Success": "Success",
        "Error": "Error",
        "Tech patient": "Tech. patient",
        "Total Value": "Total value",
        "Dashboard": "Dashboard",
        "Incomes": "Incomes",
        "Sales": "Sales",
        "Summary": "Summary",
        "Filter by": "Filter by",
        "Outline": "Outline",
        "Total sales": "Total sales",
        "Sales distribution": "Sales distribution",
        "Discarding changes": "Discarding changes",
        "Total incomes": "Total incomes",
        "Incomes per cahier": "Incomes per cahier",
        "Category": "Category",
        "Refunds distribution": "Refunds distribution",
        "Refunds per cashier": "Refunds per cashier",
        "Incomes distribution": "Incomes distribution",
        "File size must not exceed": "File size must not exceed",
        "actual size is": "actual size is",
        "Data import/export": "Data import/export",
        "Drag and drop a file here to import or use one of the following options": "Drag and drop a file here to import or use one of the following options",
        "Export": "Export",
        "Export2": "Export",
        "Initialisation": "Initialisation",
        "Completion": "Completion",
        "Import": "Import",
        "Import2": "Import",
        "File selection": "File selection",
        "We are ready to proceed": "We are ready to proceed",
        "Click on": "Click on",
        "bellow to start the import of your data": "bellow to start the import of your data",
        "Your data have been imported successfully, details are shown bellow": "Your data have been imported successfully, details are shown bellow",
        "Imported": "Imported",
        "Continue": "Continue",
        "accountsCreatedCount": "Accounts",
        "medicUnitsCreatedCount": "Medicines units",
        "medicTypesCreatedCount": "Medicines types",
        "medicinesCreatedCount": "Medicines",
        "examsCreatedCount": "Exams",
        "examTypesCreatedCount": "Exams types",
        "operationsCreatedCount": "Surgeries",
        "operationTypesCreatedCount": "Surgeries types",
        "servicesCreatedCount": "Other services",
        "Medicines types": "Medicines types",
        "Medicines units": "Medicines units",
        "What do you want to export?": "What do you want to export?",
        "All": "All",
        "Your data is ready": "Your data is ready",
        "The following items will be exported": "The following items will be exported",
        "Your data have been exported successfully": "Your data have been exported successfully",
        "New prescription": "New prescription",
        "New account": "New account",
        "New discount plan": "New discount plan",
        "New service": "New service",
        "New prices list": "New prices list",
        "New exam type": "New exam type",
        "New exam": "New exam",
        "New operation type": "New operation type",
        "New surgery": "New surgery",
        "New supplier": "New supplier",
        "New ward": "New ward",
        "New inventory": "New inventory",
        "New medicine type": "New medicine type",
        "New unit": "New unit",
        "New medicine": "New medicine",
        "New order": "New order",
        "New requisition": "New requisition request",
        "Unknown occupation": "Unknown occupation",
        "Unknown ward": "Unknown ward",
        "Unknown phone number": "Unknown phone number",
        "Unknown address": "Unknown address",
        "I confirm that payment has been received": "I confirm that payment has been received",
        "Cash receipt": "Cash receipt",
        "Collected": "Collected",
        "This is a demo with limited access to some features.": "This is a demo with limited access to some features.",
        "The image will be permanently deleted": "The image will be permanently deleted",
        "Yes": "Yes",
        "No": "No",
        "Expenses": "Expenses",
        "My expenses": "My expenses",
        "All expenses": "All expenses",
        "Date in the future not allowed": "Date in the future not allowed",
        "Employee is": "Employee is",
        "By employee" : "By employee",
        "By hospital": "By hospital",
        "Paid by employee" : "Paid by employee",
        "Paid by hospital": "Paid by hospital",
        "Filter expenses": "Filter expenses",
        "Employee": "Employee",
        "SUBMITTED": "Submitted",
        "REIMBURSED": "Reimbursed",
        "TO_REIMBURSE": "To reimburse",
        "TO_SUBMIT": "To submit",
        "Clear all filter": "Clear all filter",
        "Apply filter": "Apply filter",
        "To be submitted": "To be submitted",
        "Under validation": "Under validation",
        "To be reimbursed": "To be reimbursed",
        "New expense": "New expense",
        "Paid by": "Paid by",
        "Receipt": "Receipt",
        "Add a new expense": "Add a new expense",
        "Is this expense for an other employee?": "Is this expense for an other employee?",
        "No, it's for me": "No, it's for me",
        "Expense date": "Expense date",
        "Paid by employee (to reimburse)": "Paid by employee (to reimburse)",
        "Payer": "Payer",
        "The employee": "The employee",
        "The hospital": "The hospital",
        "Submit": "Submit",
        "Reimburse": "Reimburse",
        "Upload": "Upload",
        "Please, confirm you want to continue executing this action": "Please, confirm you want to continue executing this action.",
        "Receipt not available": "Receipt not available",
        "No file selected": "No file selected",
        "Loading, please wait...": "Loading, please wait...",
        "Value must be greater than 0": "Value must be greater than 0",
        "Discount rate per unit": "Discount rate per unit",
        "Purchase price per unit": "Purchase price per unit",
        "Total units": "Total units",
        "Total expenses": "Total expenses",
        "Expenses distribution": "Expenses distribution"
    }
}

export default translation_en;