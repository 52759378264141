import Ward from "../../common/model/Ward";
import BillableItem from "./BillableItem";
import LotInfo from "./LotInfo";
import Ordonnance from "./Ordonnance";

export default class OrdonnanceItem {
	public id?: number;	
	public version: number;
	public ordonnance: Ordonnance;	
	public billableItem: BillableItem;	
	public quantity: number;	
	public unitPrice?: number;
	public discountRate?: number;	
	public billed: boolean;
	public lotInfos?: Array<LotInfo>;
	public ward?: Ward;
	public numberPerTake?: number;
	public frequencyInDay?: number;
	public frequencyInPeriod?: number;

	constructor(ordonnance: Ordonnance, billableItem: BillableItem, quantity: number, billed = false, version: number = 1) {
		this.ordonnance = ordonnance;
		this.billableItem = billableItem;
		this.quantity = quantity;
		this.billed = billed;
		this.version = version;
	}

	clone() {
		let ordonnanceItem = new OrdonnanceItem(this.ordonnance, this.billableItem, this.quantity, this.billed, this.version);
		ordonnanceItem.id = this.id;
		ordonnanceItem.unitPrice = this.unitPrice;
		ordonnanceItem.discountRate = this.discountRate;
		ordonnanceItem.lotInfos = this.lotInfos? [...this.lotInfos] : [];
		ordonnanceItem.ward = this.ward;
		ordonnanceItem.numberPerTake = this.numberPerTake;
		ordonnanceItem.frequencyInDay = this.frequencyInDay;
		ordonnanceItem.frequencyInPeriod = this.frequencyInPeriod;
		return ordonnanceItem; 
	}
}
