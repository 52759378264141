import BillableItem from "./BillableItem";
import PriceList from "./PriceList";

export default class PriceItem {
	public id?: number;	
	public version: number;
	public priceList: PriceList;	
	public billableItem: BillableItem;
	public price: number;

	constructor(priceList: PriceList, billableItem: BillableItem, price: number, version: number = 1) {
		this.priceList = priceList;
		this.billableItem = billableItem;
		this.price = price;
		this.version = version;
	}
	
	clone() {
		let pi = new PriceItem(this.priceList, this.billableItem, this.price, this.version);
		pi.id = this.id;
		return pi;
	}
}
