import defaultImage from '../assets/images/default_image_thumbnail.png';

const MONTHS_EN = ["January", "February", "March", "April", "May", "June", 
					"July", "August", "September", "October", "November", "December"]
const MONTHS_FR = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", 
					"Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]

function getMonth(m: number, lang: string) {
	if(lang.startsWith('en')) {
		return MONTHS_EN[m];
	}
	return MONTHS_FR[m];
}

export type HomeMenuItem = {
	id: string
	icon: string
	label: string
	children?: HomeMenuItem[]
}

export default class Constants {
	public static APP_INFO = {
		NAME: "XPharma",
		DESCRIPTION: "Hospital pharmacy management",
		VERSION_NAME: "1.0.0-beta",
		BUILD_NO: "20231005"
	};
	public static AUTH_USER_STORAGE_KEY: string = "XPHARMA-AUTH-USER";
	public static AUTH_USER_REMEMBER_KEY: string = "XPHARMA-USER-REMEMBER";
	//public static AUTH_USER_AUTH_KEY: string = "XPHARMA-USER-AUTHENTICATED";
	public static AUTH_USER_AUTH_TIMESTAMP_KEY: string = "XPHARMA-USER-AUTH-TIMESTAMP";
	public static SETTINGS_STORAGE_KEY = {
		LANG: 'XPHARMA-USER-DEFAULT-LANG',
		THEME: 'XPHARMA-USER-DEFAULT-THEME'
	}

	public static BOOLEAN_PARAMETER_TRUE_VALUE: string = "yes";
	public static BOOLEAN_PARAMETER_FALSE_VALUE: string = "no";
	public static DEVICE_BREAK_POINTS = {
		SMALL: {
			MIN: 360,
			MAX: 640
		},
		MEDIUM: {
			MIN: 641,
			MAX: 1007
		},
		LARGE: {
			MIN: 1008,
			MAX: 1920
		}
	};
	public static INITIAL_PAGE: number = 0;
	public static DEFAULT_HANDLER_TIMEOUT = 5000;
	public static DEFAULT_ALERT_TIMEOUT = 5000;
	public static MODALS = {
		appInfo: "LoginAppInfoOverlay",
		picker: "pickerOverlay"
	};
	public static HEADER_HEIGHT = 60;
	public static TAB_BAR_HEIGHT = 50;
	public static FOOTER_HEIGHT = 20;
	public static FileTypes = {
		IMG: {
			PNG: "image/png",
			GIF: "image/gif",
			JPEG: "image/jpeg",
			WEBP: "image/webp"
		},
		PDF: "application/pdf"
	}
	public static CreateUuid() {
		let Time = new Date().getTime();
		let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
		.replace(/[xy]/g, function(res) {
			let Random = (Time + Math.random() * 16) % 16 | 0;
			Time = Math.floor(Time / 16);
			// eslint-disable-next-line
			return (res === 'x' ? Random : (Random & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	};
	public static IMG_DEFAULT = defaultImage;
	public static HOME_MENU_ID = {
		profile: 'profile',
		expenses: 'expenses',
		colleagues: 'colleagues',
		users: 'users',
		roles: 'roles',
		closing_visits: 'closing-visits',
		reopening_bills: 'reopening-bills',
		settings: 'settings',
		reports: 'reports',
		dashboard: 'dasboard',
		incomes: 'incomes',
		sales: 'sales',
		expensesReport: 'expenses-report',
		dataInOut: 'data-in-out'
	}
	public static HOME_MENU_ITEMS: HomeMenuItem[] = [
        {
            id: this.HOME_MENU_ID.profile,
            icon: 'person',
            label: 'My Profile'
        },
		{
            id: this.HOME_MENU_ID.expenses,
            icon: 'wallet2',
            label: 'Expenses'
        },
		{
            id: this.HOME_MENU_ID.dashboard,
            icon: 'speedometer2',
            label: 'Dashboard'
        },
		{
            id: this.HOME_MENU_ID.colleagues,
            icon: 'people',
            label: 'Colleagues'
        },
		{
            id: this.HOME_MENU_ID.users,
            icon: 'people',
            label: 'Users'
        },
		{
            id: this.HOME_MENU_ID.roles,
            icon: 'diagram-3',
            label: 'Roles'
        },
		{
            id: this.HOME_MENU_ID.closing_visits,
            icon: 'person-x',
            label: 'Closing of visits'
        },
		{
            id: this.HOME_MENU_ID.reopening_bills,
            icon: 'receipt',
            label: 'Reopening bills'
        },
		{
			id: this.HOME_MENU_ID.reports,
			icon: 'bar-chart-line',
			label: 'Reports',
			children: [
				{
					id: this.HOME_MENU_ID.sales,
					icon: '',
					label: 'Sales'
				},
				{
					id: this.HOME_MENU_ID.incomes,
					icon: '',
					label: 'Incomes'
				},
				{
					id: this.HOME_MENU_ID.expensesReport,
					icon: '',
					label: 'Expenses'
				}
			]
		},
		{
            id: this.HOME_MENU_ID.dataInOut,
            icon: 'database',
            label: 'Data import/export'
        },
		{
            id: this.HOME_MENU_ID.settings,
            icon: 'gear',
            label: 'Settings'
        }
    ]
	public static PROFILE_BANNER_DEFAULT_INDEX = 4;
	public static PROFILE_BANNER = [
		{
			id: 1,
			name: 'Animals',
			filename: 'profile_banner_animals.webp'
		},
		{
			id: 2,
			name: 'Christmas',
			filename: 'profile_banner_christmas.webp'
		},
		{
			id: 3,
			name: 'Dandelion',
			filename: 'profile_banner_dandelion.webp'
		},
		{
			id: 4,
			name: 'Digitization',
			filename: 'profile_banner_digitization.webp'
		},
		{
			id: 5,
			name: 'Emotions',
			filename: 'profile_banner_emotions.webp'
		},
		{
			id: 6,
			name: 'Geeks',
			filename: 'profile_banner_geeks.webp'
		},
		{
			id: 7,
			name: 'International womens day',
			filename: 'profile_banner_international-womens-day.webp'
		},
		{
			id: 8,
			name: 'Money',
			filename: 'profile_banner_money.webp'
		},
		{
			id: 9,
			name: 'Nature 1',
			filename: 'profile_banner_nature-1.webp'
		},
		{
			id: 10,
			name: 'Nature 2',
			filename: 'profile_banner_nature-2.webp'
		},
		{
			id: 11,
			name: 'Nature 3',
			filename: 'profile_banner_nature-3.jpg'
		},
		{
			id: 12,
			name: 'Nature 4',
			filename: 'profile_banner_nature-4.webp'
		},
		{
			id: 13,
			name: 'Office',
			filename: 'profile_banner_office.webp'
		},
		{
			id: 14,
			name: 'Pattern',
			filename: 'profile_banner_pattern.webp'
		},
		{
			id: 15,
			name: 'Sunset',
			filename: 'profile_banner_sunset.webp'
		},
		{
			id: 16,
			name: 'Universe',
			filename: 'profile_banner_universe.webp'
		}
	]
	public static getBanner(key?: string | number) {
		if(key === undefined || key === null) {
			return undefined;
		}
		const id = parseInt(key.toString());
		if(isNaN(id)) {
			return this.PROFILE_BANNER.find(it => it.filename === key);
		} else {
			return this.PROFILE_BANNER.find(it => it.id === key);
		}
	}
	public static DATE_FORMAT = {
		DATE_ONLY: 'DD/MM/YYYY',
		DATE_TIME_FULL: (lang: string) => `DD/MM/YYYY ${lang.startsWith('en')? '[at]' : '[à]'} HH:mm`,
		MONTH_DATE: (month: number, lang: string) => {
			if(lang.startsWith('en')) {
				return `[${getMonth(month, lang)}] DD, YYYY`;
			} else {
				return `DD [${getMonth(month, lang)}] YYYY`;
			}
		},
		MONTH_DATE_TIME: (month: number, lang: string) => {
			if(lang.startsWith('en')) {
				return `[${getMonth(month, lang)}] DD, YYYY [at] HH:mm`;
			} else {
				return `DD [${getMonth(month, lang)}] YYYY [à] HH:mm`;
			}
		}
	}
	public static DATA_EXPORT_EXTENSION = ".xpexport";
}
