import Ward from "../../common/model/Ward";
import Lot from "./Lot";
import Medoc from "./Medoc";

export default class Stock {
	public id?: number;	
	public version: number;
	public lot: Lot;	
	public medoc: Medoc;	
	public ward: Ward;	
	public quantity: number;
	public initialQty: number;

	constructor(lot: Lot, medoc: Medoc, ward: Ward, initialQty: number, quantity: number, version: number = 1) {
		this.lot = lot;
		this.medoc = medoc;
		this.ward = ward;
		this.initialQty = initialQty;
		this.quantity = quantity;
		this.version = version;
	}

	getTotalValue() {
		if(!this.lot.purchasePrice) {
			return 0;
		}
		const price = this.lot.purchasePrice - (!!this.lot.discountRate? (this.lot.purchasePrice * this.lot.discountRate / 100) : 0)
		return this.quantity * price;
	}

}
