import { Validable, ValidationResult } from "../../../utils/Validable";

export default class Ward implements Validable {
	public id?: number;	
	public version: number;
	public name: string;
	public hasPharmacy: boolean;
	public isPrincipal: boolean; 
	public dispensingPharmacy: boolean;
	public logo?: string;
	public description?: string;

	constructor(name: string, hasPharmacy: boolean = false, isPrincipal: boolean = false, dispensingPharmacy: boolean = false, version: number = 1) {
		this.name = name;
		this.hasPharmacy = hasPharmacy;
		this.isPrincipal = isPrincipal;
		this.dispensingPharmacy = dispensingPharmacy;
		this.version = version;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		return this.validate("name");
	}

	clone() {
		let ward: Ward = new Ward(this.name, this.hasPharmacy, this.isPrincipal, this.dispensingPharmacy, this.version);
		ward.id = this.id;
		ward.logo = this.logo;
		ward.description = this.description;
		return ward;
	}

	get displayName() {
		return this.name || 'New ward'
	}
}
