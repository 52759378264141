export default class ServiceInfo {
    remainingQuantity?: number;
    price: number;
    discountRate: number;

    constructor(price: number, discountRate: number, remainingQuantity?: number) {
        this.remainingQuantity = remainingQuantity;
        this.price = price;
        this.discountRate = discountRate;
    }
}