import Parameter from "../../app/admin/model/Parameter";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface ParameterState {
    parameters: Array<Parameter>;
}

const initialState: ParameterState = {
    parameters: []
}

export default function parameterReducer(state = initialState, action: Action<ParameterState>) {
    let nexState: ParameterState;
    switch (action.type) {
        case ActionTypes.ADD_PARAMETER:
            nexState = {
                ...state,
                parameters: merge(state.parameters, action.payload.parameters, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_PARAMETER:
            let _parameters: Array<Parameter> = [];
            state.parameters.forEach(parameter => {
                let param = action.payload.parameters.find(p => p.id === parameter.id);
                if(param) {
                    _parameters.push(param);
                } else {
                    _parameters.push(parameter);
                }
            })
            nexState = {
                ...state,
                parameters: [..._parameters]
            }
            return nexState || state;
        default:
            return state
    }

}