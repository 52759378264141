import VisitType from "../../../utils/enums/VisitType";
import { getVisitTypeDescription } from "../../../utils/Utils";
import Patient from "../../common/model/Patient";

export default class Visit {
	public id?: number;	
	public version: number;
	public refCode?: string;	
	public visitDate?: Date;
	public patient: Patient;	
	public closed: boolean;	
	public closeDate?: Date;
	public visitType: VisitType;

	constructor(patient: Patient, type: VisitType, version: number = 1) {
		this.patient = patient;
		this.closed = false;
		this.visitType = type;
		this.version = version;
	}
	
	clone() {
		let visit = new Visit(this.patient.clone(), this.visitType, this.version);
		visit.id = this.id;
		visit.refCode = this.refCode;
		visit.visitDate = this.visitDate;
		visit.closed = this.closed;
		visit.closeDate = this.closeDate
		return visit;
	}

	getMetaData(): Map<string, string> {
		let metaData = new Map<string, string>();
		metaData.set(getVisitTypeDescription(this.visitType), "");
		metaData.set("Date", this.visitDate?.toLocaleDateString() || "");
		if(this.closeDate) {
			metaData.set("Closing", this.closeDate.toLocaleDateString());
		}
		return metaData;
	}
}
