import { ValidationResult } from "../../../utils/Validable";

export default class DiscountPlan {
	public id?: number;
	public version: number;
	public name: string;
	public examRate: number;
	public medocRate: number;
	public operationRate: number;
	public otherRate: number;
	public description?: string;

	constructor(name: string, examRate: number = 0, medocRate: number = 0, operationRate: number = 0, otherRate: number = 0, version: number = 1) {
		this.name = name;
		this.examRate = examRate;
		this.medocRate = medocRate;
		this.operationRate = operationRate;
		this.otherRate = otherRate;
		this.version = version;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		} 
		return vr;
	}
	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("name").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}

	clone() {
		let dp = new DiscountPlan(this.name, this.examRate, this.medocRate, this.operationRate, this.otherRate, this.version);
		dp.id = this.id;
		dp.description = this.description;
		return dp;
	}

	get displayName() {
		return this.name || 'New discount plan'
	}
}
