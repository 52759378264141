import Hospital from "../../app/common/model/Hospital";
import { Action, ActionTypes } from "../actions";

export interface HospitalState {
    hospital?: Hospital;
}

const initialState: HospitalState = {  }

export default function hospitalReducer(state = initialState, action: Action<HospitalState>) {
    let nexState: HospitalState;
    switch (action.type) {
        case ActionTypes.SET_HOSPITAL:
            nexState = {
                ...state,
                hospital: action.payload.hospital
            }
            return nexState || state;
        default:
            return state
    }

}