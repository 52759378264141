import Medoc from "./Medoc";
import Requisition from "./Requisition";

export default class RequisitionItemView {
	public id?: number;	
	public version: number;
	public medoc: Medoc;
	public requisition: Requisition;
	public requestedQuantity: number;
	public availableQuantity: number;
	public deliveredQuantity?: number;

	constructor(requisition: Requisition, medoc: Medoc, requestedQuantity: number, availableQuantity: number, version: number = 1) {
		this.requisition = requisition;
		this.medoc = medoc;
		this.requestedQuantity = requestedQuantity;
		this.availableQuantity = availableQuantity;
		this.version = version;
	}
	
	clone() {
		let riv = new RequisitionItemView(this.requisition.clone(), this.medoc.clone(), this.requestedQuantity, this.availableQuantity, this.version);
		riv.deliveredQuantity = this.deliveredQuantity;
		riv.id = this.id;
		return riv;
	}
}
