import InventoryStatus from "../../../utils/enums/InventoryStatus";
import User from "../../admin/model/User";
import Ward from "../../common/model/Ward";

export default class Inventory {
	public id?: number;	
	public version: number;
	public refCode: string;	
	public initDate: Date;
	public closeDate?: Date;	
	public status: InventoryStatus;	
	public initiateBy: User;	
	public closeBy?: User;	
	public ward: Ward;

	constructor(refCode: string, initDate: Date, initiateBy: User, ward: Ward, status = InventoryStatus.PENDING, version: number = 1) {
		this.refCode = refCode;
		this.initDate = initDate;
		this.status = status;
		this.initiateBy = initiateBy;
		this.ward = ward;
		this.version = version;
	}

	getBadgeType() {
        switch(this.status) {
            case InventoryStatus.PENDING:
                return 'bg-secondary';
            case InventoryStatus.APPROVED:
                return 'bg-success';
            default: // InventoryStatus.CANCELLED
                return 'bg-danger';
        }
    }

	get displayName() {
		return [this.ward.name, this.refCode].join(" - ")
	}
}
