export default class City {
	public id?: number;
	public version: number;
	public name: string; 

	constructor(name: string, id?: number, version: number = 1) {
		this.name = name;
		this.id = id;
		this.version = version;
	}
	
}
