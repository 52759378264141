import Constants from "./Constants";

export type CookieConfig = {
    expires: Date
}

export function mSetCookies(cookies: string, config: CookieConfig) {
    if(!!cookies){
        document.cookie = `${cookies};path=/;samesite=lax;expires=${config.expires.toUTCString()}`
    }
}

export function mGetCookie(key: string) {
    return document.cookie
        .split(";")
        .find(entry => entry.startsWith(`${key}=`))
        ?.split("=")[1];
}

export function getCurrentConnectionTimestamp() {
    const tstp = mGetCookie(Constants.AUTH_USER_AUTH_TIMESTAMP_KEY);
    const timestamp = Number.parseInt(tstp !== undefined? tstp : '');
    if (isNaN(timestamp)) {
        return new Date();
    } else {
        return new Date(timestamp);
    }
}