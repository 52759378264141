import { Validable, ValidationResult } from "../../../utils/Validable";
import Order from "./Order";

export default class Lot implements Validable {
	public id?: number;	
	public version: number;
	public code: string | null;	
	public order?: Order;	
	public preparationDate: Date | null;
	public dueDate: Date | null;	
	public numberOfPackets?: number;
	public numberPerPacket?: number;
	public purchasePrice?: number;
	public discountRate?: number;
	public manufacturer?: string;

	constructor(code: string | null, preparationDate: Date | null, dueDate: Date | null, version: number = 1) {
		this.code = code;
		this.preparationDate = preparationDate;
		this.dueDate = dueDate;
		this.version = version;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "code") {
			if(!this.code || !this.code.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "preparationDate") {
			if(!this.preparationDate) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "dueDate") {
			if(!this.dueDate) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			} else {
				if(this.preparationDate && this.preparationDate.getTime() > this.dueDate.getTime()) {
					vr = {
						...vr,
						isValid: false,
						validationMessage: "The date of preparation must be before the expiration date"
					}
				}
			}
		}
		else if(fieldName === "numberOfPackets") {
			if(this.numberOfPackets === undefined) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			} 
			else if(this.numberOfPackets <= 0) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Value must be greater than 0"
				}
			}
		}
		else if(fieldName === "numberPerPacket") {
			if(this.numberPerPacket === undefined) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
			else if(this.numberPerPacket <= 0) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Value must be greater than 0"
				}
			}
		}
		else if(fieldName === "discountRate") {
			if(this.discountRate === undefined) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
			else if(this.discountRate < 0) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Invalid value"
				}
			}
		}
		else if(fieldName === "purchasePrice") {
			if(this.purchasePrice === undefined) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
			else if(this.purchasePrice <= 0) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Value must be greater than 0"
				}
			}
		}
		return vr;
	}
	
	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("code").isValid 
			|| !this.validate("preparationDate").isValid 
			|| !this.validate("dueDate").isValid
			|| !this.validate("numberOfPackets").isValid
			|| !this.validate("numberPerPacket").isValid
			|| !this.validate("discountRate").isValid
			|| !this.validate("purchasePrice").isValid
		) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}

	clone() {
		let lot: Lot = new Lot(this.code, this.preparationDate, this.dueDate, this.version);
		lot.id = this.id;
		lot.order = this.order? this.order.clone() : this.order;
		lot.numberOfPackets = this.numberOfPackets;
		lot.numberPerPacket = this.numberPerPacket;
		lot.purchasePrice = this.purchasePrice;
		lot.discountRate = this.discountRate;
		lot.manufacturer = this.manufacturer;
		return lot;
	}

	getDescription() {
		if(this.preparationDate && this.dueDate) {
			let desc: string = this.manufacturer? this.manufacturer + " · " : "";
			desc = desc + "P:" + this.preparationDate.toLocaleDateString();
			desc += " · ";
			desc = desc + "Exp:" + this.dueDate.toLocaleDateString();
			return desc;
		}
		return "";
	}

	get totalUnits() {
		if(!this.numberOfPackets || !this.numberPerPacket) {
			return 0;
		}
		return this.numberOfPackets * this.numberPerPacket;
	}
}
