import { ValidationResult } from "../../../utils/Validable";

export default class PriceList {
	public id?: number;
	public version: number;
	public name: string;	
	public currency: string;	
	public defaultOne: boolean;	
	public description?: string;

	constructor(name: string, currency: string, isDefault = false, version: number = 1) {
		this.name = name;
		this.currency = currency;
		this.defaultOne = isDefault;
		this.version = version;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		} 
		else if(fieldName === "currency") {
			if(!this.currency || !this.currency.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}
	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("name").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		else if(!this.validate("currency").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}
	
	clone() {
		let pl = new PriceList(this.name, this.currency, this.defaultOne, this.version);
		pl.id = this.id;
		pl.description = this.description;
		return pl;
	}

	get displayName() {
		return this.name || 'New prices list'
	}
}
