import OrdonnanceStatus from "../../../utils/enums/OrdonnanceStatus";
import VisitType from "../../../utils/enums/VisitType";
import UserView from "../../admin/model/UserView";
import Patient from "../../common/model/Patient";
import Visit from "../../healthcare/model/Visit";

export default class Ordonnance {
	public id?: number;	
	public version: number;
	public refCode: string;	
	public visit: Visit;
	public prescriptionDate: Date;
	public prescriber: UserView;
	public status: OrdonnanceStatus;
	public currency?: string;
	public note?: string;

	constructor(
		refCode: string, 
		visit: Visit, 
		prescriptionDate: Date, 
		prescriber: UserView,
		status: OrdonnanceStatus,
		version: number = 1) {
			this.refCode = refCode;
			this.visit = visit;
			this.prescriptionDate = prescriptionDate;
			this.prescriber = prescriber;
			this.status = status;
			this.version = version;
	}

	clone() {
		let ord = new Ordonnance(this.refCode, this.visit, this.prescriptionDate, this.prescriber, this.status, this.version);
		ord.id = this.id;
		ord.note = this.note;
		ord.currency = this.currency;
		return ord;
	}

	static fake() {
		return new Ordonnance(
			'',
			new Visit(new Patient(''), VisitType.URGENCY),
			new Date(),
			new UserView('', ''),
			OrdonnanceStatus.PREPARING
		);
	}

	get displayName() {
		return this.visit.patient.getFullName() + " - " + this.refCode;
	}
}
