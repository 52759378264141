import BillableItem from "./BillableItem";
import DiscountPlan from "./DiscountPlan";

export default class DiscountRate {
	public id?: number;	
	public version: number;
	public discountPlan: DiscountPlan;
	public billableItem: BillableItem;
	public rate: number;

	constructor(plan: DiscountPlan, item: BillableItem, rate: number, version: number = 1) {
		this.discountPlan = plan;
		this.billableItem = item;
		this.rate = rate;
		this.version = version;
	}
	
	clone() {
		let dr = new DiscountRate(this.discountPlan, this.billableItem, this.rate, this.version);
		dr.id = this.id;
		return dr;
	}
}
