import Constants from "../../utils/Constants";
import { mGetCookie } from "../../utils/CookieUtils";
import { Action, ActionTypes } from "../actions"

export interface UserState {
    isAuth: boolean;
    timestamp: Date;
    defaultLang?: string;
    defaultTheme?: string;
}

const time = mGetCookie(Constants.AUTH_USER_AUTH_TIMESTAMP_KEY);
const lastConnectionTimestamp = Number.parseInt(time !== undefined? time : '');

const initialState: UserState = {
    isAuth: !!time,
    timestamp: (isNaN(lastConnectionTimestamp))? new Date() : new Date(lastConnectionTimestamp),
    defaultLang: undefined,
    defaultTheme: undefined,
}

export default function userReducer(state = initialState, action: Action<UserState>) {
    let nexState: UserState;
    switch (action.type) {
        case ActionTypes.USER_AUTH:
            nexState = {
                ...state,
                isAuth: action.payload.isAuth,
                timestamp: action.payload.timestamp
            }
            return nexState || state;
        case ActionTypes.SET_LANG:
            nexState = {
                ...state,
                defaultLang: action.payload.defaultLang
            }
            return nexState || state;
        case ActionTypes.SET_THEME:
                nexState = {
                    ...state,
                    defaultTheme: action.payload.defaultTheme
                }
                return nexState || state;
        default:
            return state
    }

}