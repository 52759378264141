import moment from "moment";
import Constants from "../../../utils/Constants";

export default class CashRefundBean {
    refundDate: Date;
    cashier: string;
    amount: number;

    constructor(refundDate: Date, cashier: string, amount: number) {
        this.refundDate = refundDate;
        this.cashier = cashier;
        this.amount = amount;
    }

    get refundDateStr() {
        return moment(this.refundDate).format(Constants.DATE_FORMAT.DATE_ONLY)
    }
}