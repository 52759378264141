import moment from "moment";
import { Validable, ValidationResult } from "../../../utils/Validable";

export default class ExpenseRequest implements Validable {
    public expenseDate?: Date;
    public amount: number;
    public description?: string;
    public paidByEmployee: boolean

    constructor() {
        this.paidByEmployee = false;
        this.amount = 0;
		this.expenseDate = new Date();
    }

    validate(fieldName: string): ValidationResult {
        let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
        if(fieldName === "expenseDate") {
            const now = new Date();
            if(!!this.expenseDate && moment(this.expenseDate).isAfter(now)) {
                vr = {
					...vr,
					isValid: false,
					validationMessage: "Date in the future not allowed"
				}
            }
        }
        else if(fieldName === "amount") {
            if(this.amount <= 0) {
                vr = {
					...vr,
					isValid: false,
					validationMessage: "Invalid amount"
				}
            }
        }
        else if(fieldName === "description") {
			if(!this.description || !this.description.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
        return vr;
    }
    validateAll(): ValidationResult {
        let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("expenseDate").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		else if(!this.validate("amount").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
        else if(!this.validate("description").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
    }
    clone() {
		let expense = new ExpenseRequest();
        expense.expenseDate = this.expenseDate;
		expense.amount = this.amount;
		expense.description = this.description;
        expense.paidByEmployee = this.paidByEmployee;
		return expense;
	}
}