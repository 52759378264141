export default class PageResult<T> {
	
	public page: number;
	public totalPages: number;
	public totalElements: number;
	public content: Array<T>;
	
	constructor(page: number, totalPages: number, totalElements: number, content: Array<T>) {
		this.page = page;
		this.totalPages = totalPages;
		this.totalElements = totalElements;
		this.content = content;
	}
	
}
