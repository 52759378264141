import BillableItemType from "../../../utils/enums/BillableItemType";

export default class SalesBean {
    itemType: BillableItemType;
    item: string;
    quantity: number;
    totalAmount: number;

    constructor(itemType: BillableItemType, item: string, quantity: number, totalAmount: number) {
        this.itemType = itemType;
        this.item = item;
        this.quantity = quantity;
        this.totalAmount = totalAmount;
    }
}