import moment from "moment";
import RequisitionStatus from "../../../utils/enums/RequisitionStatus";
import User from "../../admin/model/User";
import Ward from "../../common/model/Ward";
import Constants from "../../../utils/Constants";

export default class Requisition {
	public id?: number;	
	public version: number;
	public issuer: User;	
	public requestSource: Ward;	
	public requestTarget: Ward;	
	public requestDate?: Date;	
	public status: RequisitionStatus;	
	public approuver?: User;

	constructor(issuer: User, requestSource: Ward, requestTarget: Ward, status: RequisitionStatus, version: number = 1) {
		this.issuer = issuer;
		this.requestSource = requestSource;
		this.requestTarget = requestTarget;
		this.status = status;
		this.version = version;
	}

	clone() {
		let req = new Requisition(this.issuer.clone(), this.requestSource.clone(), this.requestTarget.clone(), this.status, this.version);
		req.requestDate = this.requestDate;
		req.approuver = this.approuver;
		req.id = this.id;
		return req;
	}

	getBadgeType() {
        switch(this.status) {
            case RequisitionStatus.WAITING:
                return 'bg-secondary';
            case RequisitionStatus.APPROVED:
				return 'bg-success';
			case RequisitionStatus.CANCELLED:
				return 'bg-warning'
            default: // RequisitionStatus.REJECTED
                return 'bg-danger';
        }
    }

	get displayName() {
		return [this.requestSource.name, this.requestTarget.name].join(" > ") + " | " + moment(this.requestDate).format(Constants.DATE_FORMAT.DATE_ONLY);
	}

}
