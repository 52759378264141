import { isPhoneValid, ValidationResult } from "../../../utils/Validable";
import Ward from "../../common/model/Ward";

export default class UserView {
	public id?: number;	
	public version: number;
	public login: string;	
	public enabled?: boolean;	
	public lastName: string;	
	public ward?: Ward;
	public firstName?: string;
	public address?: string;
	public phone?: string;
	public profileImage?: string;
	public profession?: string;
	public bannerFilename?: string;
	public lastConnectionDate?: Date;

	constructor(login: string, lastName: string, version: number = 1) {
		this.login = login;
		this.lastName = lastName;
		this.version = version;
	}

	clone() {
		let usr = new UserView(this.login, this.lastName, this.version);
		usr.address = this.address;
		usr.bannerFilename = this.bannerFilename;
		usr.enabled = this.enabled;
		usr.firstName = this.firstName;
		usr.id = this.id;
		usr.lastConnectionDate = this.lastConnectionDate;
		usr.phone = this.phone;
		usr.profession = this.profession;
		usr.profileImage = this.profileImage;
		usr.ward = this.ward;
		return usr;
	}

	getFullName() {
		if(this.firstName) {
			return this.lastName + " " + this.firstName;
		}
		return this.lastName;
	}

	getMetaData(): Map<string, string> {
		let metaData = new Map<string, string>();
		if(!!this.ward) {
			metaData.set("Ward", this.ward.name);
		}
		metaData.set("Phone", this.phone || "-/-");
		return metaData;
	}

	get userAddress() {
		return this.address || 'Unknown address';
	}

	get userPhone() {
		return this.phone || 'Unknown phone number';
	}

	get userWard() {
		return this.ward?.name || 'Unknown ward';
	}

	get userProfession() {
		return this.profession || 'Unknown occupation';
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("login").isValid || !this.validate("lastName").isValid) {
			vr = {
				...vr,
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		if(this.phone && !isPhoneValid(this.phone)){
			vr = {
				...vr,
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "login") {
			if(!this.login || !this.login.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "lastName") {
			if(!this.lastName || !this.lastName.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}
}
