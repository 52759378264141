import ExamType from "../../app/common/model/ExamType";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface ExamTypeState {
    examTypes: Array<ExamType>;
}

const initialState: ExamTypeState = {
    examTypes: []
}

export default function examTypeReducer(state = initialState, action: Action<ExamTypeState>) {
    let nexState: ExamTypeState;
    switch (action.type) {
        case ActionTypes.ADD_EXAM_TYPE:
            nexState = {
                ...state,
                examTypes: merge(state.examTypes, action.payload.examTypes, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_EXAM_TYPE:
            let _examTypes: Array<ExamType> = [];
            state.examTypes.forEach(examType => {
                let eTpe = action.payload.examTypes.find(eTpe => eTpe.id === examType.id);
                if(eTpe) {
                    _examTypes.push(eTpe);
                } else {
                    _examTypes.push(examType);
                }
            })
            nexState = {
                ...state,
                examTypes: [..._examTypes]
            }
            return nexState || state;
        case ActionTypes.REMOVE_EXAM_TYPE:
            nexState = {
                ...state,
                examTypes: [...state.examTypes.filter(examType => action.payload.examTypes.findIndex(eTpe => eTpe.id === examType.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}