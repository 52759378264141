import VisitType from "../../../utils/enums/VisitType";

export default class SimpleVisit {
	public id: number;	
	public version: number;
	public refCode: string;	
	public visitDate: Date;	
	public closed: boolean;	
	public closeDate?: Date;
	public visitType: VisitType;

	constructor(id: number, refCode: string, visitDate: Date, closed: boolean, visitType: VisitType, version: number = 1) {
		this.id = id;
		this.refCode = refCode;
		this.visitDate = visitDate;
		this.closed = closed;
		this.visitType = visitType;
		this.version = version;
	}
	
	clone() {
		let visit = new SimpleVisit(this.id, this.refCode, this.visitDate, this.closed, this.visitType, this.version);
		visit.closeDate = this.closeDate;
		return visit;
	}
}
