import ParameterScope from "../../../utils/enums/ParameterScope";
import ParameterType from "../../../utils/enums/ParameterType";

export default class Parameter {
    id?: number;
    version: number;
    name: string;
    scope: ParameterScope;
    defaultValue: string;
    type: ParameterType;
    value?: string;
    description?: string;

    constructor(name: string, scope: ParameterScope, defaultValue: string, type: ParameterType, version: number = 1) {
        this.name = name;
		this.scope = scope;
		this.defaultValue = defaultValue;
		this.value = defaultValue;
		this.type = type;
        this.version = version;
    }

    clone() {
        let param = new Parameter(this.name, this.scope, this.defaultValue, this.type, this.version);
        param.id = this.id;
        param.description = this.description
        param.value = this.value;
        return param;
    }

    getValue(): string {
        if(this.value === undefined) {
            return this.defaultValue;
        }
        return this.value;
    }
}