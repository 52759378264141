export const API_VERSIONS ={
    v1: 1,
    v2: 2
}

export interface APIClientConfig {
    contextPath: string;
    proto: string;
    host: string;
    port?: number;
    demo?: boolean;
}

export const defaultAPICLientConfig: APIClientConfig = {
    contextPath: "xpharma-api",
    proto: "http", // example: https
    host: "localhost", // example: kamvusoft.com
    port: 8081 // example: 8443
}

class APIClientGlobalConfig {
    private config: APIClientConfig = defaultAPICLientConfig;

    public get(): APIClientConfig {
        return this.config;
    }

    public set(value: APIClientConfig): void {
        this.config = value;
    }
}

export const apiClientConfig = new APIClientGlobalConfig();

export const APIClientConfigUrl = "server-config.json";