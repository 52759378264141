import PriceList from "../../app/accounting/model/PriceList";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface PriceListState {
    priceLists: Array<PriceList>;
}

const initialState: PriceListState = {
    priceLists: []
}

export default function priceListReducer(state = initialState, action: Action<PriceListState>) {
    let nexState: PriceListState;
    switch (action.type) {
        case ActionTypes.ADD_PRICE_LIST:
            nexState = {
                ...state,
                priceLists: merge(state.priceLists, action.payload.priceLists, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_PRICE_LIST:
            let _priceLists: Array<PriceList> = [];
            state.priceLists.forEach(priceList => {
                let pl = action.payload.priceLists.find(_pl => _pl.id === priceList.id);
                if(pl) {
                    _priceLists.push(pl);
                } else {
                    _priceLists.push(priceList);
                }
            })
            nexState = {
                ...state,
                priceLists: [..._priceLists]
            }
            return nexState || state;
        case ActionTypes.REMOVE_PRICE_LIST:
            nexState = {
                ...state,
                priceLists: [...state.priceLists.filter(priceList => action.payload.priceLists.findIndex(_pl => _pl.id === priceList.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}