import Lot from "../../pharmacy/model/Lot";
import OrdonnanceItemView from "./OrdonnanceItemView";

export default class RefundItem {
	public id?: number;
	public version: number;
	public ordonnanceItem: OrdonnanceItemView;
	public lot?: Lot;
	public refundedQuantity: number;

	constructor(ordonnanceItem: OrdonnanceItemView, refundedQuantity: number, lot?: Lot, version: number = 1) {
		this.ordonnanceItem = ordonnanceItem;
		this.lot = lot;
		this.refundedQuantity = refundedQuantity;
		this.version = version;
	}

	getTotalAmount() {
		if(this.ordonnanceItem.unitPrice !== undefined) {
			const discount = this.ordonnanceItem.discountRate || 0;
			return this.refundedQuantity * this.ordonnanceItem.unitPrice * (1 - discount/100);
		}
		return 0;
	}
}
