import UserView from "../../admin/model/UserView";
import BillPaymentItem from "./BillPaymentItem";
import Ordonnance from "./Ordonnance";

export default class Payment {
	public id?: number;	
	public version: number;
	public refCode?: number;
	public ordonnance: Ordonnance;
	public paymentDate?: Date;
	public amount: number;
	public cashier: UserView;
	public details?: Array<BillPaymentItem>;

	constructor(ordonnance: Ordonnance, amount: number, cashier: UserView, version: number = 1) {
		this.ordonnance = ordonnance;
		this.amount = amount;
		this.cashier = cashier;
		this.version = version;
	}

	clone() {
		let payment = new Payment(this.ordonnance, this.amount, this.cashier, this.version);
		payment.paymentDate = this.paymentDate;
		payment.refCode = this.refCode;
		payment.id = this.id;
		return payment;
	}
}
