import { isEmailValid, isPhoneValid, Validable, ValidationResult } from "../../../utils/Validable";

export default class Supplier implements Validable {
	public id?: number;
	public version: number;	
	public name: string;	
	public address?: string;
	public phone?: string;
	public email?: string;

	constructor(name: string, version: number = 1) {
		this.name = name;
		this.version = version;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("name").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		if((!!this.email && !isEmailValid(this.email)) || (!!this.phone && !isPhoneValid(this.phone))){
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}

	clone() {
		let supplier: Supplier = new Supplier(this.name, this.version);
		supplier.id = this.id;
		supplier.address = this.address;
		supplier.phone = this.phone;
		supplier.email = this.email;
		return supplier;
	}

	get displayName() {
		return this.name || 'New supplier'
	}
}
