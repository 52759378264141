import PageResult from "../../../utils/PageResult";
import ExpenseBean from "./ExpenseBean";

export default class PaginatedResultOfExpenseBeans extends PageResult<ExpenseBean>{
    public totalAmountPaidByHospital!: number;
    public totalAmountPaidByEmployee!: number;

    get totalExpenses() {
        return this.totalAmountPaidByEmployee + this.totalAmountPaidByHospital
    }
}