import { formatNumber } from "../../../utils/Utils";
import OrdonnanceItemView from "./OrdonnanceItemView";

export default class BillPaymentItem {
	public id?: number;
	public version: number;
	public ordonnanceItem: OrdonnanceItemView;
	public collectedAmount: number;

	constructor(ordonnanceItem: OrdonnanceItemView, collectedAmount: number, version: number = 1) {
		this.ordonnanceItem = ordonnanceItem;
		this.collectedAmount = collectedAmount;
		this.version = version;
	}

	getPaymentConfirmMetaData(): Map<string, string> {
		let metaData = new Map<string, string>();
		metaData.set("Quantity", this.ordonnanceItem.getQty().toString());
		metaData.set("Amount", this.ordonnanceItem.getTotalPriceAsString());
		metaData.set("Collected", formatNumber(this.collectedAmount).toString());
		return metaData;
	}
}
