import OperationType from "../../app/common/model/OperationType";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface OperationTypeState {
    operationTypes: Array<OperationType>;
}

const initialState: OperationTypeState = {
    operationTypes: []
}

export default function operationTypeReducer(state = initialState, action: Action<OperationTypeState>) {
    let nexState: OperationTypeState;
    switch (action.type) {
        case ActionTypes.ADD_OPERATION_TYPE:
            nexState = {
                ...state,
                operationTypes: merge(state.operationTypes, action.payload.operationTypes, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_OPERATION_TYPE:
            let _operationTypes: Array<OperationType> = [];
            state.operationTypes.forEach(operationType => {
                let oTpe = action.payload.operationTypes.find(oTpe => oTpe.id === operationType.id);
                if(oTpe) {
                    _operationTypes.push(oTpe);
                } else {
                    _operationTypes.push(operationType);
                }
            })
            nexState = {
                ...state,
                operationTypes: [..._operationTypes]
            }
            return nexState || state;
        case ActionTypes.REMOVE_OPERATION_TYPE:
            nexState = {
                ...state,
                operationTypes: [...state.operationTypes.filter(operationType => action.payload.operationTypes.findIndex(oTpe => oTpe.id === operationType.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}