import Medoc from "./Medoc";
import Order from "./Order";

export default class OrderItem {
	public id?: number;	
	public version: number;
	public order: Order;
	public medoc: Medoc;
	public quantity: number;
	public deliveredQuantity?: number;

	constructor(order: Order, medoc: Medoc, quantity: number, deliveredQuantity?: number, version: number = 1) {
		this.order = order;
		this.medoc = medoc;
		this.quantity = quantity;
		this.deliveredQuantity = deliveredQuantity;
		this.version = version;
	}

	clone() {
		let orderItem = new OrderItem(this.order.clone(), this.medoc.clone(), this.quantity, this.deliveredQuantity, this.version);
		orderItem.id = this.id;
		return orderItem;
	}
}
