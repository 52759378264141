import React from 'react'
import { useTranslation } from 'react-i18next'
import './index.css'

interface Props {
    matchContent?: boolean,
    small?: boolean
}

function LoadingIndicator(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={props.matchContent? "mh-5" : "match-parent"}>
            {props.small?
                <div className="spinner-border spinner-border-sm" role="status"></div>
            :
                <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="visually-hidden">{t("Loading")}...</span>
                </div>
            }
        </div>
    )
}

export default LoadingIndicator;