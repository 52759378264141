import BillableItemType from "../../../utils/enums/BillableItemType";
import { Validable, ValidationResult } from "../../../utils/Validable";
import Account from "./Account";

export default class BillableItem implements Validable {
	public id?: number;
	public version: number;
	public name: string;
	public billableItemtype: BillableItemType;
	public account?: Account;
	public description?: string;

	constructor(name: string, billableItemtype: BillableItemType, version: number = 1) {
		this.name = name;
		this.billableItemtype = billableItemtype;
		this.version = version;
	}
	
	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		return this.validate("name");
	}

	clone() {
		let bItem: BillableItem = new BillableItem(this.name, this.billableItemtype, this.version);
		bItem.id = this.id;
		bItem.description = this.description;
		bItem.account = this.account? this.account.clone() : undefined;
		return bItem;
	}

	get displayName() {
		return this.name || 'New service'
	}
}
