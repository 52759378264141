import BillableItemType from "../../../utils/enums/BillableItemType";

export default class SalesByCategoryBean {
    category: BillableItemType;
    totalAmount: number;

    constructor(category: BillableItemType, totalAmount: number) {
        this.category = category;
        this.totalAmount = totalAmount;
    }
}