export default class ArrayMapper {
    static map<I, O>(mapOne: (input: I) => O | undefined, inputs: I[]): O[] {
        let outputs: O[] = [];
        inputs.forEach(input_ => {
            const output = mapOne(input_);
            if(output) {
                outputs.push(output);
            }
        })
        return outputs;
    };
}