export const ActionTypes = {
    USER_AUTH: 'USER-AUTHENTICATED',
    SET_LANG: 'SET-LANGUAGE',
    SET_THEME: 'SET-THEME',
    
    ADD_WARDS: 'ADD-WARDS',
    UPDATE_WARDS: 'UPDATE-WARDS',
    REMOVE_WARDS: 'REMOVE-WARDS',

    ADD_SUPPLIER: 'ADD-SUPPLIER',
    UPDATE_SUPPLIER: 'UPDATE-SUPPLIER',
    REMOVE_SUPPLIER: 'REMOVE-SUPPLIER',

    ADD_OPERATION_TYPE: 'ADD-OPERATION-TYPE',
    UPDATE_OPERATION_TYPE: 'UPDATE-OPERATION-TYPE',
    REMOVE_OPERATION_TYPE: 'REMOVE-OPERATION-TYPE',

    ADD_EXAM_TYPE: 'ADD-EXAM-TYPE',
    UPDATE_EXAM_TYPE: 'UPDATE-EXAM-TYPE',
    REMOVE_EXAM_TYPE: 'REMOVE-EXAM-TYPE',

    ADD_ACCOUNT: 'ADD-ACCOUNT',
    UPDATE_ACCOUNT: 'UPDATE-ACCOUNT',
    REMOVE_ACCOUNT: 'REMOVE-ACCOUNT',

    ADD_MEDOC_TYPE: 'ADD-MEDOC-TYPE',
    UPDATE_MEDOC_TYPE: 'UPDATE-MEDOC-TYPE',
    REMOVE_MEDOC_TYPE: 'REMOVE-MEDOC-TYPE',

    ADD_MEDOC_UNIT: 'ADD-MEDOC-UNIT',
    UPDATE_MEDOC_UNIT: 'UPDATE-MEDOC-UNIT',
    REMOVE_MEDOC_UNIT: 'REMOVE-MEDOC-UNIT',

    ADD_PRICE_LIST: 'ADD-PRICE-LIST',
    UPDATE_PRICE_LIST: 'UPDATE-PRICE-LIST',
    REMOVE_PRICE_LIST: 'REMOVE-PRICE-LIST',

    ADD_DISCOUNT_PLAN: 'ADD-DISCOUNT-PLAN',
    UPDATE_DISCOUNT_PLAN: 'UPDATE-DISCOUNT-PLAN',
    REMOVE_DISCOUNT_PLAN: 'REMOVE-DISCOUNT-PLAN',

    SET_HOSPITAL: 'SET-HOSPITAL',

    ADD_PARAMETER: 'ADD-PARAMETER',
    UPDATE_PARAMETER: 'UPDATE-PARAMETER',
}

export interface Action<T> {
    type: string,
    payload: T
}