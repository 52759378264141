import moment from "moment";
import Constants from "../../../utils/Constants";

export default class IncomeBean {
    incomeDate: Date;
    cashier: string;
    amount: number;

    constructor(incomeDate: Date, cashier: string, amount: number) {
        this.incomeDate = incomeDate;
        this.cashier = cashier;
        this.amount = amount;
    }

    get incomeDateStr() {
        return moment(this.incomeDate).format(Constants.DATE_FORMAT.DATE_ONLY)
    }
}