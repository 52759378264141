import Account from "../../app/accounting/model/Account";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface AccountState {
    accounts: Array<Account>;
}

const initialState: AccountState = {
    accounts: []
}

export default function accountReducer(state = initialState, action: Action<AccountState>) {
    let nexState: AccountState;
    switch (action.type) {
        case ActionTypes.ADD_ACCOUNT:
            nexState = {
                ...state,
                accounts: merge(state.accounts, action.payload.accounts, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_ACCOUNT:
            let _accounts: Array<Account> = [];
            state.accounts.forEach(account => {
                let a = action.payload.accounts.find(a => a.id === account.id);
                if(a) {
                    _accounts.push(a);
                } else {
                    _accounts.push(account);
                }
            })
            nexState = {
                ...state,
                accounts: [..._accounts]
            }
            return nexState || state;
        case ActionTypes.REMOVE_ACCOUNT:
            nexState = {
                ...state,
                accounts: [...state.accounts.filter(account => action.payload.accounts.findIndex(a => a.id === account.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}