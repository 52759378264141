import Supplier from "../../app/common/model/Supplier";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface SupplierState {
    suppliers: Array<Supplier>;
}

const initialState: SupplierState = {
    suppliers: []
}

export default function supplierReducer(state = initialState, action: Action<SupplierState>) {
    let nexState: SupplierState;
    switch (action.type) {
        case ActionTypes.ADD_SUPPLIER:
            nexState = {
                ...state,
                suppliers: merge(state.suppliers, action.payload.suppliers, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_SUPPLIER:
            let _suppliers: Array<Supplier> = [];
            state.suppliers.forEach(supplier => {
                let w = action.payload.suppliers.find(w => w.id === supplier.id);
                if(w) {
                    _suppliers.push(w);
                } else {
                    _suppliers.push(supplier);
                }
            })
            nexState = {
                ...state,
                suppliers: [..._suppliers]
            }
            return nexState || state;
        case ActionTypes.REMOVE_SUPPLIER:
            nexState = {
                ...state,
                suppliers: [...state.suppliers.filter(supplier => action.payload.suppliers.findIndex(w => w.id === supplier.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}