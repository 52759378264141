import moment from "moment";
import BillableItemType from "./enums/BillableItemType";
import OrdonnanceStatus from "./enums/OrdonnanceStatus";
import VisitType from "./enums/VisitType";
import ExpenseStatus from "./enums/ExpenseStatus";

export interface PopupMenuAction {
    iconName: string;
    label: string;
    handler: () => void;
}

export function getAsBillableItemType(type: string) {
    switch(type) {
        case BillableItemType.MEDOC:
            return BillableItemType.MEDOC;
        case BillableItemType.EXAM:
            return BillableItemType.EXAM;
        case BillableItemType.OPERATION:
            return BillableItemType.OPERATION;
        default:
            return BillableItemType.SERVICE;
    }
}

export function getBadgeByBillableItemType(type: BillableItemType) {
    switch(type) {
        case BillableItemType.MEDOC:
            return 'bg-success';
        case BillableItemType.EXAM:
            return 'bg-info';
        case BillableItemType.OPERATION:
            return 'bg-warning';
        default:
            return 'bg-secondary';
    }
}

export function getAsOrdonnanceStatus(status: string) {
    switch(status) {
        case OrdonnanceStatus.BILLED:
            return OrdonnanceStatus.BILLED;
        case OrdonnanceStatus.PAID:
            return OrdonnanceStatus.PAID;
        default:
            return OrdonnanceStatus.PREPARING;
    }
}

export function formatNumber(num?: number, minDec = 0, maxDec = 2): string {
    if(num === undefined) {
        return "";
    }
    let formater = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: minDec, 
        maximumFractionDigits: maxDec
    });
    return formater.format(num);
}

export function replace<T>(item: T, items: T[], lookup: (it1: T, it2: T) => boolean) {
    const index = items.findIndex(it => lookup(item, it));
    let updatedList: T[];
    const N = items.length - 1;
    if(index === 0) {
        const rightSlice = items.slice(1);
        updatedList = [item, ...rightSlice];
    } else if(index === N) {
        const leftSlice = items.slice(0, N);
        updatedList = [...leftSlice, item];
    } else {
        const leftSlice = items.slice(0, index);
        const rightSlice = items.slice(index + 1);
        updatedList = [...leftSlice, item, ...rightSlice];
    }
    return updatedList;
}

export type BillFormAction = 'edit' | 'delete' | 'close' | 'reopen' | 'confirm';

export type BillStats = {
    nbElements: number
    totalAmount: number
}

export type BillRefundStats = {
    medoc: BillStats,
    cash: BillStats
}

export function getLabel(action?: BillFormAction) {
    switch (action) {
        case 'confirm':
            return 'Confirm';
        case 'close':
            return 'Close2';
        case 'reopen':
            return 'Reopen';
        case 'edit':
            return 'Open';
        case 'delete':
            return 'Delete';
        default:
            return ''
    }
}

export function getAsVisitType(type: string) {
    switch(type) {
        case VisitType.APPOINTMENT:
            return VisitType.APPOINTMENT;
        case VisitType.CONSULTATION:
            return VisitType.CONSULTATION;
        case VisitType.URGENCY:
            return VisitType.URGENCY;
        default:
            return VisitType.PHARMACY;
    }
}

export function getVisitTypeDescription(type: VisitType) {
    switch(type) {
        case VisitType.APPOINTMENT:
            return 'Appointment';
        case VisitType.CONSULTATION:
            return 'Consultation';
        case VisitType.URGENCY:
            return 'Emergency';
        default:
            return 'Pharmacy (buying medicines)';
    }
}

export function fromNow(time: Date, lang: string) {
    return day(time, lang);
}

function day(time: Date, lang: string) {
    let diff = moment(new Date()).diff(time);
    const DAY = 24 * 60 * 60 * 1000;
    const q = Math.floor(diff / DAY);
    const r = diff % DAY;
    if(q > 0) {
        return q + (lang.startsWith("en")? " day(s)" : " jour(s)");
    } else {
        return hour(r, lang);
    }
}

function hour(milliss: number,  lang: string) {
    const HOUR = 60 * 60 * 1000;
    const q = Math.floor(milliss / HOUR);
    const r = milliss % HOUR;
    if(q > 0) {
        return (new Intl.NumberFormat(undefined, {
            minimumIntegerDigits: 2
        })).format(q) + 'h:' + minute(r, lang);
    } else {
        return minute(r, lang);
    }
}

function minute(milliss: number,  lang: string) {
    const MIN = 60 * 1000;
    const q = Math.floor(milliss / MIN);
    //const r = milliss % MIN;
    if(q > 0) {
        return q + 'min';
    } else {
        return (lang.startsWith("en")? " few seconds" : " quelques secondes");
    }
}

export function merge<T>(array1: T[], array2: T[], equals: (e1: T, e2: T) => boolean) {
    const mergedArray: T[] = [];
    array1.forEach(it => mergedArray.push(it));
    array2.forEach(it => {
        if(array1.findIndex(e => equals(e, it)) < 0) {
            mergedArray.push(it);
        }
    });
    return mergedArray;
}

export type DateRangeFilterModel = {
    dateFrom?: Date;
    dateTo?: Date;
}

export function getBillableItemTypeDisplayString(type: BillableItemType) {
    switch(type) {
        case BillableItemType.MEDOC:
            return 'Medicines';
        case BillableItemType.EXAM:
            return 'Exams';
        case BillableItemType.OPERATION:
            return 'Operations';
        default:
            return 'Other services';
    }
}

export function generateColors(N: number) {
    let colors = [];
    for (let index = 0; index < N; index++) {
        const randomColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
        colors.push(randomColor);
    }
    return colors
}

export function getAsExpenseStatus(status: string) {
    switch(status) {
        case ExpenseStatus.APPROVED:
            return ExpenseStatus.APPROVED;
        case ExpenseStatus.REIMBURSED:
            return ExpenseStatus.REIMBURSED;
        case ExpenseStatus.REJECTED:
            return ExpenseStatus.REJECTED;
        case ExpenseStatus.SUBMITTED:
            return ExpenseStatus.SUBMITTED;
        case ExpenseStatus.TO_REIMBURSE:
            return ExpenseStatus.TO_REIMBURSE;
        case ExpenseStatus.TO_SUBMIT:
            return ExpenseStatus.TO_SUBMIT;
        default:
            return undefined;
    }
}

export function getBadgeByExpenseStatus(status: ExpenseStatus) {
    switch(status) {
        case ExpenseStatus.APPROVED:
            return 'bg-success';
        case ExpenseStatus.REIMBURSED:
            return 'bg-success';
        case ExpenseStatus.REJECTED:
            return 'bg-danger';
        case ExpenseStatus.SUBMITTED:
            return 'bg-warning';
        case ExpenseStatus.TO_REIMBURSE:
            return 'bg-info';
        case ExpenseStatus.TO_SUBMIT:
            return 'bg-secondary';
        default:
            return '';
    }
}

export function unique<T>(list: Array<T>, equals: (e1: T, e2: T) => boolean) {
    let output: T[] = [];
    list.forEach(it => {
        if(output.findIndex(value => equals(value, it)) < 0) {
            output.push(it);
        }
    });
    return output;
}