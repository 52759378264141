export default class InventoryItemPrepare {
    medocId: number;
    lotId: number;
    inventoryId?: number;
    wardId?: number;
	duplicated?: boolean;
    knownQuantity?: number;
    
    constructor(medocId: number, lotId: number) {
        this.medocId = medocId;
        this.lotId = lotId;
    }
    
}