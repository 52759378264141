export default class RefundsBean {
    item: string;
    quantity: number;
    totalAmount: number;

    constructor(item: string, quantity: number, totalAmount: number) {
        this.item = item;
        this.quantity = quantity;
        this.totalAmount = totalAmount;
    }
}