import { isEmailValid, isPhoneValid, Validable, ValidationResult } from '../../../utils/Validable';
import Patient from './Patient';

export default class Hospital implements Validable {
	public id?: number;	
	public version: number;
	public name: string;	
	public city: string;	
	public healthArea?: string;
	public logo?: string;
	public address?: string;
	public phone?: string;
	public email?: string;
	public pobox?: string;
	public technicalPatient?: Patient;

	constructor(name: string, city: string, version: number = 1) {
		this.name = name;
		this.city = city;
		this.version = version;
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("name").isValid || !this.validate("city").isValid) {
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		if((!!this.email && !isEmailValid(this.email)) || (!!this.phone && !isPhoneValid(this.phone))){
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}
		return vr;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === "city") {
			if(!this.city || !this.city.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	clone() {
		let hospital: Hospital = new Hospital(this.name, this.city, this.version);
		hospital.id = this.id;
		hospital.address = this.address;
		hospital.email = this.email;
		hospital.healthArea = this.healthArea;
		hospital.logo = this.logo;
		hospital.phone = this.phone;
		hospital.pobox = this.pobox;
		hospital.technicalPatient = this.technicalPatient?.clone();
		return hospital;
	}
}
