import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import LoadingIndicator from './components/LoadingIndicator';
import { Provider } from "react-redux";
import { getStore } from './store';
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
import { APIClientConfigUrl, apiClientConfig } from './api/config';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// import i18n (needs to be bundled ;)) 
import './i18n';

const App = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

const app = (
  <React.StrictMode>
    <Provider store={getStore()}>
      <Router basename="/">
        <Suspense fallback={<LoadingIndicator/>}>
          <App />
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>
)
axios
  .get(APIClientConfigUrl)
  .then(response => {
    apiClientConfig.set(response.data);
    return app;
  })
  .catch(e => {
    return (
      <h6 className='text-center text-danger p-5'>
        <p style={{fontSize: 48}}>😢</p>
        <p>Error while fetching API client configs.</p>
        <p>The app will not be rendered.</p>
      </h6>
    )
  })
  .then(reactElement => {
    root.render(reactElement);
  })


