import { Validable, ValidationResult } from "../../../utils/Validable";

export default class MedocType implements Validable {
	public id?: number;	
	public version: number;
	public name: string;
	public description?: string;

	constructor(name: string, version: number = 1) {
		this.name = name;
		this.version = version;
	}

	validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === "name") {
			if(!this.name || !this.name.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}
	
	validateAll(): ValidationResult {
		return this.validate("name");
	}

	clone() {
		let medocType: MedocType = new MedocType(this.name, this.version);
		medocType.id = this.id;
		medocType.description = this.description;
		return medocType;
	}

	get displayName() {
		return this.name || 'New medicine type'
	}
	
}
