import Ward from "../../common/model/Ward";
import BillableItem from "./BillableItem";

export default class OrdonnanceItemCreate {
	public id?: number;	
	public version: number;
	public billableItem: BillableItem;	
	public quantity: number;	
	public numberPerTake?: number;
	public frequencyInDay?: number;
	public frequencyInPeriod?: number;
	public ward?: Ward;

	constructor(billableItem: BillableItem, quantity: number, version: number = 1) {
		this.billableItem = billableItem;
		this.quantity = quantity;
		this.version = version;
	}

	clone() {
		let ordonnanceItem = new OrdonnanceItemCreate(this.billableItem, this.quantity, this.version);
		ordonnanceItem.id = this.id;
		ordonnanceItem.numberPerTake = this.numberPerTake;
		ordonnanceItem.frequencyInDay = this.frequencyInDay;
		ordonnanceItem.frequencyInPeriod = this.frequencyInPeriod;
		ordonnanceItem.ward = this.ward
		return ordonnanceItem;
	}

	getMetaData(): Map<string, string> {
		let metaData = new Map<string, string>();
		metaData.set("Quantity", this.quantity.toString());
		if(this.numberPerTake !== undefined && this.numberPerTake !== null) {
			metaData.set("Qty per take", this.numberPerTake.toString());
		}
		if(this.frequencyInDay !== undefined && this.frequencyInDay !== null) {
			metaData.set("Frequency (day)", this.frequencyInDay.toString());
		}
		if(this.frequencyInPeriod !== undefined && this.frequencyInPeriod !== null) {
			if(this.frequencyInPeriod === 1) {
				metaData.set("Everyday", "");
			} else {
				metaData.set("Frequency (period)", this.frequencyInPeriod.toString());
			}
		}
		return metaData;
	}
}
