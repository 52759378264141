import AccountingMappers from "../accounting/AccountingMappers";
import AdminMappers from "../admin/AdminMappers";
import CommonMappers from "../common/CommonMappers";
import Inventory from "./model/Inventory";
import InventoryItem from "./model/InventoryItem";
import InventoryItemPrepare from "./model/InventoryItemPrepare";
import Lot from "./model/Lot";
import MedicalDispensing from "./model/MedicalDispensing";
import Medoc from "./model/Medoc";
import MedocType from "./model/MedocType";
import MedocUnit from "./model/MedocUnit";
import Order from "./model/Order";
import OrderItem from "./model/OrderItem";
import Requisition from "./model/Requisition";
import RequisitionItemView from "./model/RequisitionItemView";
import Stock from "./model/Stock";
import StockMovement from "./model/StockMovement";

const PharmacyMappers = {
    mapMedocType: function(data: any) {
        if(!data) return undefined;
        let medocType: MedocType = new MedocType(data.name, data.version);
		medocType.id = data.id;
		medocType.description = data.description;
		return medocType;
    },
    mapMedocUnit: function(data: any) {
        if(!data) return undefined;
        let medocUnit: MedocUnit = new MedocUnit(data.name, data.version);
		medocUnit.id = data.id;
		medocUnit.description = data.description;
		return medocUnit;
    },
    mapMedoc: function(data: any) {
        if(!data) return undefined;
        let mt = this.mapMedocType(data.medocType);
        let mu = this.mapMedocUnit(data.unit);
        if(!mt || !mu) return undefined;
        let medoc: Medoc = new Medoc(data.name, mt, mu, data.version);
		medoc.account = AccountingMappers.mapAccount(data.account);
		medoc.description = data.description;
		medoc.id = data.id;
		medoc.code = data.code;
		medoc.minQuantity = data.minQuantity;
		medoc.packaging = data.packaging;
        medoc.shape = data.shape;
        medoc.outdatedQuantity = data.outdatedQuantity;
		medoc.availableQuantity = data.availableQuantity;
		return medoc;
    },
    mapOrder(data: any) {
        if(!data) return undefined;
        let user_ = AdminMappers.mapUser(data.issuer);
        let supplier_ = CommonMappers.mapSupplier(data.supplier);
        if(!user_ || !supplier_) return undefined;
		let order = new Order(user_, supplier_, data.version);
		order.id = data.id;
		order.orderDate = new Date(data.orderDate);
		order.deliveryDate = new Date(data.deliveryDate);
        order.orderStatus = data.orderStatus;
        return order;
	},
    mapLot(data: any) {
		if(!data) return undefined;
		let lot: Lot = new Lot(data.code, new Date(data.preparationDate), new Date(data.dueDate), data.version);
		lot.id = data.id;
		if(data.order) {
            lot.order = this.mapOrder(data.order);
        }
		lot.numberOfPackets = data.numberOfPackets;
		lot.numberPerPacket = data.numberPerPacket;
		lot.purchasePrice = data.purchasePrice;
		lot.discountRate = data.discountRate;
        lot.manufacturer = data.manufacturer;
        return lot;
	},
    mapInventory: function(data: any) {
        if(!data) return undefined;
        let user_ = AdminMappers.mapUser(data.initiateBy);
        let ward_ = CommonMappers.mapWard(data.ward);
        if(!user_ || !ward_) return undefined;
        let inventory: Inventory = new Inventory(
			data.refCode, 
			new Date(data.initDate),
			user_,
			ward_,
			data.status,
            data.version
        );
        inventory.id = data.id;
        if(data.closeDate) {
            inventory.closeDate = new Date(data.closeDate);
        }
        if(data.closeBy) {
            inventory.closeBy = AdminMappers.mapUser(data.closeBy);
        }
		return inventory;
    },
    mapInventoryItem(data: any) {
        if(!data) return undefined;
        let inventory_ = this.mapInventory(data.inventory);
        let medoc_ = this.mapMedoc(data.medoc);
        let lot_ = this.mapLot(data.lot);
        if(!inventory_ || !medoc_ || !lot_) return undefined;
		let inventoryItem: InventoryItem = new InventoryItem(inventory_, medoc_, lot_, data.knownQuantity, data.realQuantity, data.version);
		inventoryItem.id = data.id;
		return inventoryItem;
    },
    mapInventoryItemPrepare(data: any) {
        let prepData = new InventoryItemPrepare(data.medocId, data.lotId);
        prepData.inventoryId = data.inventoryId;
        prepData.wardId = data.wardId;
        prepData.duplicated = data.duplicated;
        prepData.knownQuantity = data.knownQuantity;
        return prepData;
    },
    mapStock(data: any) {
		if(!data) return undefined;
        let lot_ = this.mapLot(data.lot);
        let medoc_ = this.mapMedoc(data.medoc);
        let ward_ = CommonMappers.mapWard(data.ward);
        if(!lot_ || !medoc_ || !ward_) return undefined;
        let stock = new Stock(lot_, medoc_, ward_, data.initialQty, data.quantity, data.version);
        stock.id = data.id;
		return stock;
	},
    mapOrderItem(data: any) {
        if(!data) return undefined;
        let order_ = this.mapOrder(data.order)
        let medoc_ = this.mapMedoc(data.medoc);
        if(!order_ || !medoc_) return undefined;
        let orderItem = new OrderItem(order_, medoc_, data.quantity, data.deliveredQuantity, data.version)
        orderItem.id = data.id;
        return orderItem;
    },
    mapRequisition(data: any) {
        if(!data) return undefined;
        let issuer_ = AdminMappers.mapUser(data.issuer);
        let wardSource_ = CommonMappers.mapWard(data.requestSource);
        let wardTarget_ = CommonMappers.mapWard(data.requestTarget);
        if(!issuer_ || !wardSource_ || !wardTarget_) {
            return undefined;
        }
        let req = new Requisition(issuer_, wardSource_, wardTarget_, data.status, data.version);
		if(data.requestDate){
            req.requestDate = new Date(data.requestDate);
        }
        req.approuver = AdminMappers.mapUser(data.approuver);
		req.id = data.id;
		return req;
    },
    mapRequisitionItemView(data: any) {
        if(!data) return undefined;
        let requisition_ = this.mapRequisition(data.requisition);
        let medoc_ = this.mapMedoc(data.medoc);
        if(!requisition_ || !medoc_) {
            return undefined;
        }
        let riv = new RequisitionItemView(requisition_, medoc_, data.requestedQuantity, data.availableQuantity, data.version);
		riv.deliveredQuantity = data.deliveredQuantity;
		riv.id = data.id;
		return riv;
    },
    mapMedicalDispensing(data: any) {
        if(!data) return undefined;
        let ward = CommonMappers.mapWard(data.ward);
        let medical = this.mapMedoc(data.medical);
        if(!ward || !medical) {
            return undefined;
        }
        let md = new MedicalDispensing(data.quantity, data.comment, ward, data.version);
        md.id = data.id;
        md.medical = medical;
        md.dispensingDate = new Date(data.dispensingDate);
        return md;
    },
    mapStockMovement(data: any) {
        if(!data) return undefined;
        let medical = this.mapMedoc(data.medical);
        if(!medical) {
            return undefined;
        }
        let stockMov = new StockMovement();
        stockMov.id = data.id;
        stockMov.version = data.version;
        stockMov.movementType = data.movementType;
        stockMov.movementDate = data.movementDate;
        stockMov.medical = medical;
        stockMov.quantity = data.quantity;
        stockMov.stockBefore = data.stockBefore;
        stockMov.stockAfter = data.stockAfter;
        stockMov.sourceType = data.sourceType;
        stockMov.source = data.source;
        stockMov.destinationType = data.destinationType;
        stockMov.destination = data.destination;
        return stockMov;
    }
}

export default PharmacyMappers;