import DiscountPlan from "../../app/accounting/model/DiscountPlan";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface DiscountPlanState {
    discountPlans: Array<DiscountPlan>;
}

const initialState: DiscountPlanState = {
    discountPlans: []
}

export default function discountPlanReducer(state = initialState, action: Action<DiscountPlanState>) {
    let nexState: DiscountPlanState;
    switch (action.type) {
        case ActionTypes.ADD_DISCOUNT_PLAN:
            nexState = {
                ...state,
                discountPlans: merge(state.discountPlans, action.payload.discountPlans, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_DISCOUNT_PLAN:
            let _discountPlans: Array<DiscountPlan> = [];
            state.discountPlans.forEach(discountPlan => {
                let dp = action.payload.discountPlans.find(_dp => _dp.id === discountPlan.id);
                if(dp) {
                    _discountPlans.push(dp);
                } else {
                    _discountPlans.push(discountPlan);
                }
            })
            nexState = {
                ...state,
                discountPlans: [..._discountPlans]
            }
            return nexState || state;
        case ActionTypes.REMOVE_DISCOUNT_PLAN:
            nexState = {
                ...state,
                discountPlans: [...state.discountPlans.filter(discountPlan => action.payload.discountPlans.findIndex(_dp => _dp.id === discountPlan.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}