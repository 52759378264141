import MedocUnit from "../../app/pharmacy/model/MedocUnit";
import { merge } from "../../utils/Utils";
import { Action, ActionTypes } from "../actions"

export interface MedocUnitState {
    medocUnits: Array<MedocUnit>;
}

const initialState: MedocUnitState = {
    medocUnits: []
}

export default function medocUnitReducer(state = initialState, action: Action<MedocUnitState>) {
    let nexState: MedocUnitState;
    switch (action.type) {
        case ActionTypes.ADD_MEDOC_UNIT:
            nexState = {
                ...state,
                medocUnits: merge(state.medocUnits, action.payload.medocUnits, (it1, it2) => it1.id === it2.id)
            }
            return nexState || state;
        case ActionTypes.UPDATE_MEDOC_UNIT:
            let _medocUnits: Array<MedocUnit> = [];
            state.medocUnits.forEach(medocUnit => {
                let eTpe = action.payload.medocUnits.find(eTpe => eTpe.id === medocUnit.id);
                if(eTpe) {
                    _medocUnits.push(eTpe);
                } else {
                    _medocUnits.push(medocUnit);
                }
            })
            nexState = {
                ...state,
                medocUnits: [..._medocUnits]
            }
            return nexState || state;
        case ActionTypes.REMOVE_MEDOC_UNIT:
            nexState = {
                ...state,
                medocUnits: [...state.medocUnits.filter(medocUnit => action.payload.medocUnits.findIndex(eTpe => eTpe.id === medocUnit.id) < 0)]
            }
            return nexState || state;
        default:
            return state
    }

}