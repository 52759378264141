import Role from "./Role";
import UserView from "./UserView";

export default class UserToken {
	public token: string;	
	public refreshToken: string;
	public user: UserView;	
	public roles: Array<Role>;
	
	constructor(token: string, refreshToken: string, user: UserView, roles: Array<Role>) {
		this.token = token;
		this.refreshToken = refreshToken;
		this.user = user;
		this.roles = roles;
	}

}
