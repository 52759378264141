import OrdonnanceStatus from "../../../utils/enums/OrdonnanceStatus";
import UserView from "../../admin/model/UserView";
import Visit from "../../healthcare/model/Visit";
import Ordonnance from "./Ordonnance";

export default class OrdonnanceSummary {
	public id?: number;	
	public version: number;
	public refCode: string;	
	public visit: Visit;
	public prescriptionDate: Date;
	public prescriber: UserView;
	public status: OrdonnanceStatus;
	public currency?: string;
	public note?: string;	
	public initialAmount?: number;	
	public currentAmount?: number;	
	public balance?: number;

	constructor(
		refCode: string, 
		visit: Visit, 
		prescriptionDate: Date, 
		prescriber: UserView,
		status: OrdonnanceStatus,
		version: number = 1) {
			this.refCode = refCode;
			this.visit = visit;
			this.prescriptionDate = prescriptionDate;
			this.prescriber = prescriber;
			this.status = status;
			this.version = version;
	}

	getBadgeType() {
        switch(this.status) {
            case OrdonnanceStatus.PREPARING:
                return 'bg-secondary';
            case OrdonnanceStatus.BILLED:
                return 'bg-info';
            default: // OrdonnanceStatus.PAID
                return 'bg-success';
        }
    }

	toOrdonnance() {
		let ord = new Ordonnance(this.refCode, this.visit, this.prescriptionDate, this.prescriber, this.status, this.version);
		ord.id = this.id;
		ord.note = this.note;
		ord.currency = this.currency;
		return ord;
	}
}
