import VisitType from "../../../utils/enums/VisitType";
import Patient from "../../common/model/Patient";

export default class VisitClosing {
    public id: number;	
    public version: number;
	public refCode: string;	
	public visitDate: Date;	
    public patient: Patient;
	public visitType: VisitType;
    public balance: number;
    public openedBills: number;

    constructor(
        id: number,	
        refCode: string,	
        visitDate: Date,	
        patient: Patient,
        visitType: VisitType,
        balance: number,
        openedBills: number,
        version: number = 1) {
            this.id = id;
            this.refCode = refCode;
            this.visitDate = visitDate;
            this.patient = patient;
            this.visitType = visitType;
            this.balance = balance;
            this.openedBills = openedBills;
            this.version = version;
        }
}