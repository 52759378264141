import StockMovementLocation from "../../../utils/enums/StockMovementLocation";
import StockMovementType from "../../../utils/enums/StockMovementType";
import Medoc from "./Medoc";

export default class StockMovement {
    id!: number;
    version!: number;
    movementType!: StockMovementType;
    movementDate!: Date;
    medical!: Medoc;
    quantity!: number;
    stockBefore!: number;
    stockAfter!: number;
    sourceType!: StockMovementLocation;
    source!: string;
    destinationType!: StockMovementLocation;
    destination!: string;
}