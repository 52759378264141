import { Validable, ValidationResult } from "../../../utils/Validable";
import Ward from "../../common/model/Ward";
import Medoc from "./Medoc";

export default class MedicalDispensing implements Validable {
    id!: number;
	version: number;
    ward!: Ward;
    medical?: Medoc;
    quantity: number;
    dispensingDate!: Date;
    comment: string;

    constructor(quantity: number, comment: string, ward?: Ward, version: number = 1) {
        this.quantity = quantity;
        this.comment = comment;
		if(ward) {
			this.ward = ward;
		}
		this.version = version;
    }

    validate(fieldName: string): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(fieldName === 'comment') {
			if(!this.comment.trim()) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		else if(fieldName === 'quantity') {
			if(this.quantity <= 0) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Invalid quantity"
				}
			}
		}
		else if(fieldName === "ward") {
			if(!this.ward) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
        else if(fieldName === "medical") {
			if(!this.medical) {
				vr = {
					...vr,
					isValid: false,
					validationMessage: "Mandatory field"
				}
			}
		}
		return vr;
	}

	validateAll(): ValidationResult {
		let vr: ValidationResult = {
			isValid: true,
			validationMessage: ""
		}
		if(!this.validate("comment").isValid || 
            !this.validate("quantity").isValid ||
            !this.validate("medical").isValid ||
            !this.validate("ward").isValid) 
		{
			vr = {
				isValid: false,
				validationMessage: "errors in form"
			}
		}

		return vr;
	}

    clone() {
        let md = new MedicalDispensing(this.quantity, this.comment, this.ward, this.version);
        md.id = this.id;
        md.medical = this.medical;
        md.dispensingDate = this.dispensingDate;
        return md;
    }
}