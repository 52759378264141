import { combineReducers, createStore } from "redux";
import AdminMappers from "../app/admin/AdminMappers";
import UserToken from "../app/admin/model/UserToken";
import Constants from "../utils/Constants";
import accountReducer, { AccountState } from "./reducer/account";
import discountPlanReducer, { DiscountPlanState } from "./reducer/discountPlan";
import examTypeReducer, { ExamTypeState } from "./reducer/examType";
import hospitalReducer, { HospitalState } from "./reducer/hospital";
import medocTypeReducer, { MedocTypeState } from "./reducer/medocType";
import medocUnitReducer, { MedocUnitState } from "./reducer/medocUnit";
import operationTypeReducer, { OperationTypeState } from "./reducer/operationType";
import parameterReducer, { ParameterState } from "./reducer/parameter";
import priceListReducer, { PriceListState } from "./reducer/priceList";
import supplierReducer, { SupplierState } from "./reducer/supplier";
import userReducer, { UserState } from './reducer/user';
import wardReducer, { WardState } from "./reducer/ward";

export class MyLocalStorage {
    /**
     * Retreave a data to the local storage
     * @param key - the key of the data
     */
     static get(key: string,) {
        const data = localStorage.getItem(key);
        if(data !== null)
            return  JSON.parse(data);
        else 
            return undefined;
    }

    /**
     * Saves a data to the local storage
     * @param key - the key using to store the data (must be unique)
     * @param data - the data to store
     */
    static save(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    /**
     * Deletes the data associated with the geven key, if a key/value pair with the given key exists.
     * @param key - the key of the data to delete
     */
    static delete(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * Get the authenticated user
     * @returns The authenticated user or null if it is not found
     */
    static getAuthenticatedUser(): UserToken | undefined {
        const result = localStorage.getItem(Constants.AUTH_USER_STORAGE_KEY);
        if(result) {
            let uT = AdminMappers.mapUserToken(JSON.parse(result));
            return uT;
        } else {
            return undefined;
        }
    }
  
    static shouldRememberUser(): boolean {
        const result = localStorage.getItem(Constants.AUTH_USER_REMEMBER_KEY);
        if(result) {
            const value = JSON.parse(result);
            return value === Constants.BOOLEAN_PARAMETER_TRUE_VALUE;
        }
        return false;
    }
}

export function hasAuthority(authority: string) {
    let authToken = MyLocalStorage.getAuthenticatedUser();
    if(authToken) {
        return authToken.roles.findIndex(it => it.type === authority) >= 0;
    }
    return false;
}

export function hasAnyAuthority(authorities: string[]) {
    let authToken = MyLocalStorage.getAuthenticatedUser();
    if(authToken) {
        return authToken.roles.findIndex(it => authorities.findIndex(auth => it.type === auth) >= 0) >= 0;
    }
    return false;
}

export interface RootState {
    user: UserState;
    ward: WardState;
    supplier: SupplierState;
    operationType: OperationTypeState;
    examType: ExamTypeState;
    account: AccountState;
    medocType: MedocTypeState;
    medocUnit: MedocUnitState;
    priceList: PriceListState;
    discountPlan: DiscountPlanState;
    hospital: HospitalState;
    parameter: ParameterState;
}

export function getStore() {
    return createStore(combineReducers({
        user: userReducer,
        ward: wardReducer,
        supplier: supplierReducer,
        operationType: operationTypeReducer,
        examType: examTypeReducer,
        account: accountReducer,
        medocType: medocTypeReducer,
        medocUnit: medocUnitReducer,
        priceList: priceListReducer,
        discountPlan: discountPlanReducer,
        hospital: hospitalReducer,
        parameter: parameterReducer
    }));
}
