import AccountingMappers from "../accounting/AccountingMappers";
import HealthCareMappers from "../healthcare/HealthCareMappers";
import City from "./model/City";
import Exam from "./model/Exam";
import ExamType from "./model/ExamType";
import Hospital from "./model/Hospital";
import Operation from "./model/Operation";
import OperationType from "./model/OperationType";
import Patient from "./model/Patient";
import Supplier from "./model/Supplier";
import Ward from "./model/Ward";

const CommonMappers = {
    mapWard: function(data: any) {
        if(!data) return undefined;
        let ward = new Ward(data.name, data.hasPharmacy, data.isPrincipal, data.dispensingPharmacy, data.version);
        ward.id = data.id;
        ward.logo = data.logo;
        ward.description = data.description;
        return ward;
    },
    mapSupplier: function(data: any) {
        if(!data) return undefined;
        let supplier = new Supplier(data.name, data.version);
        supplier.id = data.id;
		supplier.address = data.address;
		supplier.phone = data.phone;
		supplier.email = data.email;
		return supplier;
    },
    mapOperationType: function(data: any) {
        if(!data) return undefined;
        let operationType: OperationType = new OperationType(data.name, data.version);
		operationType.id = data.id;
		operationType.description = data.description;
		return operationType;
    },
    mapExamType: function(data: any) {
        if(!data) return undefined;
        let examType: ExamType = new ExamType(data.name, data.version);
		examType.id = data.id;
		examType.description = data.description;
		return examType;
    },
    mapOperation: function(data: any) {
        if(!data) return undefined;
        let operationType = this.mapOperationType(data.operationType);
        if(!operationType) return undefined;

        let operation: Operation = new Operation(data.name, operationType, data.version);
		operation.account = AccountingMappers.mapAccount(data.account);
		operation.description = data.description;
		operation.id = data.id;
		return operation;
    },
    mapExam: function(data: any) {
        if(!data) return undefined;
        let examType = this.mapExamType(data.examType);
        if(!examType) return undefined;

        let exam: Exam = new Exam(data.name, examType, data.version);
		exam.account = AccountingMappers.mapAccount(data.account);
		exam.description = data.description;
		exam.id = data.id;
		return exam;
    },
    mapPatient: function(data: any) {
        if(!data) return undefined;
        let pt = new Patient(data.lastName, data.sex, data.inArea, data.version);
		pt.id = data.id;
		pt.code = data.code;
		pt.firstName = data.firstName;
		pt.dateOfBirth = data.dateOfBirth? new Date(data.dateOfBirth) : undefined;
		pt.bloodType = data.bloodType;
		pt.maritalStatus = data.maritalStatus;
		pt.numberOfChildren = data.numberOfChildren;
		pt.placeOfBirth = data.placeOfBirth;
		pt.profession = data.profession;
		pt.profileImage = data.profileImage;
		pt.personToContact = data.personToContact;
		pt.personToContactAddress = data.personToContactAddress;
		pt.personToContactPhone = data.personToContactPhone;
		pt.city = data.city;
		pt.address = data.address;
		pt.phone = data.phone;
		pt.note = data.note;
		if(data.priceList) {
			pt.priceList = AccountingMappers.mapPriceList(data.priceList);
		}
		if(data.discountPlan) {
			pt.discountPlan = AccountingMappers.mapDiscountPlan(data.discountPlan);
		}
		if(data.currentVisit) {
			pt.currentVisit = HealthCareMappers.mapSimpleVisit(data.currentVisit);
		}
		return pt;
	},
	mapCity: function(data: any) {
		if(!data) return undefined;
		return new City(data.name, data.id, data.version);
	},
	mapHospital: function(data: any) {
		if(!data) return undefined;
		const patient = this.mapPatient(data.technicalPatient);
		if(patient === undefined) return undefined;

		let hospital: Hospital = new Hospital(data.name, data.city, data.version);
		hospital.id = data.id;
		hospital.address = data.address;
		hospital.email = data.email;
		hospital.healthArea = data.healthArea;
		hospital.logo = data.logo;
		hospital.phone = data.phone;
		hospital.pobox = data.pobox;
		hospital.technicalPatient = patient;
		return hospital;
	}
}

export default CommonMappers;