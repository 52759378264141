const translation_fr = { 
    translation: {
        "App under dev": "L'application XPharma est en cours de dévelopment",
        "Welcome": "Bienvenue",
        "User name": "Nom d'utilisateur",
        "Password": "Mot de passe",
        "Log In": "Connexion",
        "Remember me": "Se souvenir de moi",
        "Home": "Accueil",
        "Hospital": "Hôpital",
        "Cashier": "Caissier",
        "Pharmacy": "Pharmacie",
        "Store": "Stock",
        "Parameters": "Paramètres",
        "en": "English",
        "fr": "Français",
        "Log out": "Déconnexion",
        "HOSPITAL PHARMACY MANAGEMENT": "GESTION DES PHARMACIES HOSPITALIÈRES",
        "New": "Nouveau",
        "Medicine": "Médicament",
        "Patient": "Patient",
        "No result to show": "Aucun résultat à afficher",
        "Invalid email": "adresse email invalide",
        "Invalid phone number": "le numéro de téléphone doit avoir 9 chiffres commençant par 6 ou 2",
        "Mandatory field": "Ce champ est obligatoire",
        "errors in form": "Il y a des erreurs dans le formulaire",
        "Unknown error": "Erreur inconnue",
        "Reports": "Rapports",
        "Operation succeeded": "Opération réussie!",
        "Visit": "Visite",
        "Ordonnance": "Ordonnance",
        "The date of preparation must be before the expiration date": "La date de préparation doit être antérieure à la date d'expiration",
        "Administration": "Admin",
        "Patients": "Patients",
        "Accounting": "Comptabilité",
        "Cash register": "Caisse",
        "Password mismatch": "Ne correspond pas au mot de passe saisi",
        "user": "Utilisateur",
        "recorder": "Régistraire",
        "doctor": "Docteur",
        "cashier": "Caissier",
        "accountant": "Comptable",
        "pharmacist": "Pharmacien",
        "stock_manager": "Gestionnaire de stock",
        "admin": "Administrateur",
        "app_admin": "Administrateur d'application",
        "no": "non",
        "yes": "oui",
        "Invalid quantity": "Quantité invalide",
        "Medicines": "Médicaments",
        "Orders": "Commandes",
        "Requisition requests": "Demandes de réquisition",
        "Stock control": "Controleur de stock",
        "Types of medicines": "Types de médicaments",
        "Units": "Unités",
        "Required operation pending. Try later": "Opération requise en attente. Essayez plus tard",
        "Prescriptions": "Prescriptions",
        "Error: please check your connection": "Erreur : veuillez vérifier votre connexion",
        "Hospital pharmacy management": "Gestion des pharmacies hospitalières",
        "Wards": "Pavillons",
        "Hospital Services": "Services de l'hôpital",
        "Operations": "Chirurgies",
        "Exams": "Examens",
        "Operations types": "Types de chirurgies",
        "Exams types": "Types d'examens",
        "Suppliers": "Fournisseurs",
        "My Profile": "Mon Profil",
        "Colleagues": "Collègues",
        "Users": "Utilisateurs",
        "Roles": "Rôles",
        "Closing of visits": "Clôture des visites",
        "Reopening bills": "Réouverture des factures",
        "Settings": "Configurations",
        "Application's version": "Version de l'application",
        "Build ID": "Numéro de build",
        "Prices lists": "Listes des prix",
        "Discount plans": "Plans de réduction",
        "Accounts": "Comptes",
        "Welcome to the XPharma Setup Wizard": "Bienvenu dans l'assistant d'installation de XPharma",
        "Please complete the installation so that your application is ready for use": "Veuillez completer l'installation pour que votre application soit prête pour l'utilisation",
        "Make sure you have deployed the server correctly": "Assurez-vous d'avoir correctement déployé le serveur",
        "The server is correctly deployed and working": "Le serveur est correctement déployé et fonctionne",
        "The web client is properly configured": "Le client web est bien configuré",
        "Installation Wizard": "Assistant d'installation",
        "Prerequisites": "Préréquis",
        "Hospital settings": "Paramétrage de l'hôpital",
        "Admin account": "Compte administrateur",
        "Installation completed": "Fin de l'installation",
        "Back": "Retour",
        "Install": "Installer",
        "Return to the login page": "Retourner à la page de connexion",
        "Next": "Suivant",
        "Cancel": "Annuler",
        "Basic information": "Informations de base",
        "Hospital name": "Nom de l'hôpital",
        "City": "Ville",
        "Health area": "Aire de santé",
        "Contact": "Contact",
        "Address": "Adresse",
        "Phone": "Téléphone",
        "E-mail": "Courriel",
        "P.O. Box": "Boîte postale",
        "Congratulations": "Félicitations",
        "You have completed all the steps": "Vous avez complété toutes les étapes",
        "Your application has been successfully installed": "Votre application a été installée avec succès",
        "Go back to the login page to take advantage of it now": "Retournez à la page de connexion pour en profiter dès maintenant",
        "Access parameters": "Paramètres d'accès",
        "Personal information": "Informations personnelles",
        "Last name": "Nom",
        "First name": "Prénom",
        "You are connected since": "Vous êtes connecté depuis",
        "Search": "Recherchez",
        "Loading": "Chargement",
        "Edit": "Modifier",
        "Delete": "Supprimer",
        "No item found": "Aucun élément trouvé",
        "Invalid city name": "Nom de ville invalide",
        "New city": "Nouvelle ville",
        "Confirm the selection": "Confirmer la sélection",
        "No item selected": "Aucun élément sélectionné",
        "Create new": "Nouveau",
        "No patient found": "Aucun patient trouvé",
        "New patient": "Nouveau patient",
        "No medicine found": "Aucun médicament trouvé",
        "New lot": "Nouveau lot",
        "Unable to start the camera": "Impossible de démarrer la caméra",
        "Invalid file type": "Type de fichier invalide",
        "Drag and drop image here or use one of the following options": "Glissez et déposez l'image ici ou utilisez l'une des options suivantes",
        "Capture": "Capturer",
        "Browse": "Parcourir",
        "Quit": "Quitter",
        "No file loaded": "Aucun fichier chargé",
        "of": "sur",
        "Displays": "Affiche",
        "items": "éléments",
        "Previous": "Précédent",
        "Warning": "Attention",
        "No item available": "Aucun élément disponible",
        "more(s)": "autre(s)",
        "Save": "Enregistrer",
        "Lot code": "Code du lot",
        "Preparation date": "Date de préparation",
        "Expiration date": "Date d'expiration",
        "Manufacturer": "Fabriquant",
        "Number of packets": "Nombre de paquets",
        "Quantity per packet": "Quantité par paquet",
        "Discount": "Réduction",
        "Details": "Détails",
        "Clear": "Effacer",
        "Filter": "Filtrer",
        "No available data to display": "Aucune donnée disponible à afficher",
        "Inventories": "Inventaires",
        "Requisitions": "Réquisitions",
        "Internal use": "Consommation Interne",
        "Close": "Fermer",
        "No pharmacy found": "Aucune pharmacie trouvée",
        "No description provided": "Description non définie",
        "Open": "Ouvrir",
        "Lot": "Lot",
        "Initial quantity": "Quantité initiale",
        "Critical quantity": "Quantité critique",
        "In stock": "En stock",
        "Purchase price": "Prix d'achat",
        "Critical stock reached": "Stock critique atteint",
        "Expired medicine": "Médicament périmé",
        "From": "De",
        "To": "À",
        "to": "à",
        "Expiration": "Péremption",
        "Requisition": "Réquisition",
        "Suppression in progress": "Suppression en cours",
        "Cancellation in progress": "Annulation en cours",
        "Reopening the requisition": "Réouverture de la réquisition en cours",
        "Reopening the order": "Réouverture de la commande en cours",
        "Rejecting the requisition": "Rejet de la réquisition en cours",
        "Proceed to approval": "Procéder à l'approbation",
        "Reject": "Rejeter",
        "Reopen": "Réouvrir",
        "Source": "Source",
        "Choose": "Choisir",
        "Outgoing requisition requests": "Demandes de réquisition sortantes",
        "Incoming requisition requests": "Demandes de réquisition entrantes",
        "Target": "Destination",
        "Status": "Statut",
        "All statuses": "Tous les statuts",
        "APPROVED": "Approuvé",
        "CANCELLED": "Annulé",
        "WAITING": "En attente",
        "REJECTED": "Rejeté",
        "Order status": "Etat de la commande",
        "Order date": "Date de la commande",
        "Requisition status": "Etat de la réquisition",
        "Requisition date": "Date de la réquisition",
        "No target ward selected": "Aucun pavillon cible sélectionné",
        "Unable to save the request": "Impossible d'enregistrer la demande",
        "Unable to approve the request": "Impossible d'approuver la demande",
        "One or more invalid requisitioned quantities": "Une ou plusieurs quantités réquisitionées invalides",
        "Invalid medicine or quantity": "Médicament ou quantité invalide",
        "The medicine is already listed": "Le médicament est déjà présent dans la liste",
        "Failed to prepare the item": "Échec de la préparation de l'élément",
        "Initiated by": "Initié(e) par",
        "Initiated on": "Initié(e) le",
        "New item": "Nouvel élément",
        "Select": "Selectionner",
        "Quantity": "Quantité",
        "Available": "Disponible",
        "Add": "Ajouter",
        "Do you really want to delete this item?": "Voulez-vous vraiment supprimer cet élément?",
        "Requested": "Demamdé",
        "Requisitioned": "Réquisitioné",
        "Invalid value": "Valeur invalide",
        "Save request": "Enregistrer la demande",
        "Order": "Commande",
        "PREPARING": "En préparation",
        "DELIVERED": "Livré",
        "Delivering": "Livraison",
        "Ordered quantity": "Quantité commandée",
        "Qty": "Qté",
        "Save order": "Enregistrer la commande",
        "One or more missing lots": "Un ou plusieurs lots manquants",
        "Received quantity": "Quantité reçue",
        "Unable to create the order, please check the supplier": "Impossible de créer la commande, veuillez vérifier le fournisseur",
        "Order details": "Détails de la commande",
        "Supplier": "Fournisseur",
        "Delivered on": "Livrée le",
        "Name": "Nom",
        "Description": "Description",
        "Code": "Code",
        "available(s)": "disponible(s)",
        "expired": "périmé(s)",
        "Type of medicine": "Type de médicament",
        "Undefined": "Non défini",
        "Unit": "Unité",
        "Packaging": "Emballage",
        "Shape": "Forme",
        "Account": "Compte",
        "Medicine's name": "Nom du médicament",
        "All types": "Tous les types",
        "Comment": "Commentaire",
        "List of consumed items": "Liste des éléments Consommés",
        "Date": "Date",
        "Confirm": "Confirmer",
        "Please confirm the internal consumption of": "Veuillez confirmer la consommation interne de",
        "PENDING": "En cours",
        "Reopening the inventory": "Réouverture de l'inventaire en cours",
        "Validating the inventory": "Validation de l'inventaire en cours",
        "Inventory status": "État de l'inventaire",
        "Creation date": "Date de création",
        "General": "Général",
        "Items": "Eléments",
        "Add items to the inventory in the Items tab": "Ajoutez des éléments à l'inventaire dans l'onglet Eléments",
        "Reference": "Référence",
        "Closed by": "Cloturé par",
        "Closing date": "Date de clôture",
        "Known Qty": "Qté théorique",
        "Actual Qty": "Qté Réelle",
        "There is nothing to save": "There is nothing to save",
        "One or more incorrect entries": "Une ou plusieurs entrées incorrectes",
        "Duplicated item in the inventory": "Élément dupliqué dans l'inventaire",
        "Duplicated lot code": "Code de lot dupliqué",
        "Delete selection": "Supprimer la sélection",
        "Cancel selection": "Annuler la sélection",
        "New Entry": "Nouvelle Entrée",
        "Total price": "Prix total",
        "No meta data available": "Aucune méta-donnée disponible",
        "Approve": "Approuver",
        "Main": "Principal",
        "Ward with pharmacy": "Pavillon avec pharmacie",
        "Ward without pharmacy": "Pavillon sans pharmacie",
        "Dispensing pharmacy": "Pharmacie de distribution",
        "Sex": "Sexe",
        "Male": "Homme",
        "Female": "Femme",
        "Visit closed": "Visite close",
        "Visit pending": "Visite en cours",
        "There is currently no ongoing visit": "Aucune visite en cours",
        "There is actually an ongoing visit": "Une visite en cours actuellement",
        "New visit": "Nouvelle visite",
        "Born in": "Né(e) à",
        "Born on": "Né(e) le",
        "Closing": "Clôture",
        "Address and contacts": "Adresse et contacts",
        "Medical information": "Données médicales",
        "Single": "Célibataire",
        "Married": "Marié(e)",
        "In cohabitation": "En concubinage",
        "Divorced": "Divorsé(e)",
        "Widow": "Voeuf/Voeuve",
        "Date of birth": "Date de naissance",
        "Place of birth": "Lieu de naissance",
        "Marital status": "Statut matrimonial",
        "Number of children": "Nombre d'enfants",
        "Occupation": "Profession",
        "City of residence": "Ville de résidence",
        "Within the area": "Dans l'aire",
        "Who to contact": "Personne à contacter",
        "Address of the contact person": "Adresse de la personne à contacter",
        "Phone number of the contact person": "Téléphone de la personne à contacter",
        "Blood type": "Groupe sanguin",
        "Notes": "Notes",
        "Delete the picture": "Supprimer la photo",
        "Visits": "Visites",
        "Closed visits": "Visites closes",
        "Ongoing visits": "Visites en cours",
        "Please close the current visit": "Veuillez clôturer la visite courante",
        "Do you really want to delete": "Voulez-vous vraiment supprimer",
        "this patient": "ce patient",
        "this visit": "cette visite",
        "This action is destructive and irreversible": "Cette action est destructrice et irréversible",
        "You have cancelled the operation": "Vous avez annulé l'opération",
        "No visits found": "Aucune visite trouvée",
        "Visit date": "Date de la visite",
        "Visit type": "Type de visite",
        "Pharmacy (buying medicines)": "Pharmacie (achat de médicaments)",
        "Appointment": "Rendez-vous",
        "Consultation": "Consultation",
        "Emergency": "Urgence",
        "Duplicated item": "Élément en double",
        "Unable to perform the operation, please try again later": "Impossible d'effectuer l'opération, veuillez réessayer plus tard",
        "No patient selected": "Aucun patient sélectionné",
        "No items to be saved": "Aucun élément à enregistrer",
        "Error occured": "Une erreur est survenue",
        "Qty per take": "Qté par prise",
        "Frequency (day)": "Fréquence (journée)",
        "Frequency (period)": "Fréquence (période)",
        "Prescriber": "Prescripteur",
        "Other services": "Autres services",
        "Prescribed items": "Eléments prescrits",
        "Everyday": "Tous les jours",
        "Date of prescription": "Date de la prescription",
        "Operation type": "Type de chirurgie",
        "Exam type": "Type d'examen",
        "Are you sure you want to": "Êtes-vous sûr de vouloir",
        "deactivate": "désactiver",
        "Deactivate": "Désactiver",
        "activate": "activer",
        "Activate": "Activer",
        "delete": "supprimer",
        "this user": "cet utilisateur",
        "Reset password": "Réinitialiser le mot de passe",
        "Reallocate": "Réaffecter",
        "New user": "Nouvel utilisateur",
        "Username": "Nom d'utilisateur",
        "Confirm password": "Confirmez le mot de passe",
        "Ward": "Pavillon",
        "Full name": "Nom complet",
        "Last connection": "Dernière connexion",
        "ENABLED": "ACTIVÉ",
        "DISABLED": "DÉSACTIVÉ",
        "No user found": "Aucun utilisateur trouvé",
        "Password reset": "Réinitialisation du mot de passe",
        "Reallocation": "Réaffectation",
        "New password": "Nouveau mot de passe",
        "Target ward": "Pavillon cible",
        "Some visits have not been closed": "Certaines visites n'ont pas été clôturées",
        "We found no visit that can be closed": "Nous n'avons trouvé aucune visite succeptible d'être clôturée",
        "selected": "sélectionnée(s)",
        "Close2": "Clôturer",
        "Reason": "Motif",
        "Opened bills": "Factures ouvertes",
        "Total balance": "Solde total",
        "No pendig visit found": "Aucune visite en cours trouvée",
        "Please, confirm you are closing": "Veuillez confirmer la clôture de",
        "visit(s)": "visite(s)",
        "Default": "Par défaut",
        "Value": "Valeur",
        "No parameter found": "Aucun paramètre trouvé",
        "Hospital Infos": "Informations de l'hôpital",
        "Roles and permissions": "Rôles et privilèges",
        "Role": "Rôle",
        "Manage": "Gérer",
        "No role found": "Aucun rôle trouvé",
        "All users": "Tous les utilisateurs",
        "Role's users": "Utilisateurs du rôle",
        "Change my password": "Changer mon mot de passe",
        "No previous login recorded": "Aucune connexion précédente enregistrée",
        "Current session": "Session courante",
        "Account information": "Informations du compte",
        "Preferences": "Préférences",
        "Default language": "Langue par défaut",
        "Color theme": "Thème de couleur",
        "Are you sure you want to delete your profile image?": "Êtes-vous sûr(e) de vouloir suprimmer votre photo de profil?",
        "Apply": "Appliquer",
        "The new password must be different to the old one": "Le nouveau mot de passe doit être différent de l'ancien",
        "Change your password": "Changez votre mot de passe",
        "Old password": "Ancien mot de passe",
        "Incorrect price": "Prix incorrect",
        "Service": "Service",
        "Price": "Prix",
        "No price defined": "Aucun prix défini",
        "Operation": "Chirurgie",
        "Exam": "Examen",
        "The patient is already listed": "Le patient est déjà présent dans la liste",
        "Associated patients list": "Liste des patient associés",
        "Select patient": "Sélectionnez le patient",
        "No patient associated to this prices' list": "Aucun patient associé à cette liste de prix",
        "The default prices list is applied to patients with no other prices list applied to them": "La liste des prix par défaut est appliquée aux patients n'ayant aucune autre liste de prix qui leur est appliquée",
        "List name": "Nom de la liste",
        "Currency": "Monaie",
        "Prices defined in the list": "Prix définis dans la liste",
        "Manage items": "Gérer les éléments",
        "Service name": "Nom du service",
        "Rates applied to specific elements": "Taux appliqués à des éléments spécifiques",
        "Incorrect rate": "Taux incorrect",
        "Taux": "Rate",
        "Do you really want to delete this discount rate": "Voulez-vous vraiment supprimer ce taux de réduction?",
        "No discount rate defined": "Aucun taux de réduction défini",
        "Rate": "Taux",
        "No related patient": "Aucun patient lié",
        "Plan's name": "Nom du plan",
        "Discount rates": "Taux de réduction",
        "Account name": "Nom du compte",
        "Account number": "Numéro du compte",
        "Do you want to continue?": "Voulez-vous continuer?",
        "bill": "facture",
        "No bill found": "Aucune facture trouvée",
        "Some bills have not been reopened": "Certaines factures n'ont pas été réouvertes",
        "We found no bill that can be reopened": "Nous n'avons trouvé aucune facture succeptible d'être réouverte",
        "No closed bill found": "Aucune facture close trouvée",
        "Please confirm you want to reopen": "Veuillez confirmer la réouverture de",
        "bill(s)": "facture(s)",
        "Opened": "Ouverte",
        "Closed": "Close",
        "New Bill": "Nouvelle Facture",
        "Created by": "Créée par",
        "Payments": "Paiements",
        "Refunds": "Remboursements",
        "Create": "Créer",
        "You have unsaved changes, they will be discarded if you quit": "Vous avez des modifications non enregistrées, vous les perdrez si vous quittez",
        "Bill created on": "Facture créée le",
        "Total items": "Nombre d'élements",
        "Total amount": "Montant total",
        "Initial amount": "Montant initial",
        "Cash refunds": "Remboursements de cash",
        "Balance": "Solde",
        "Not Applicable": "Not Reportable",
        "Unable to save the payment, try again later": "Impossible d'enregistrer le paiment, veuillez réessayer plus tard",
        "Nothing to refund": "Aucun élément à rembourser",
        "Operation not permited": "Opération non autorisée",
        "Please enter the refund's reason": "Veuillez préciser la raison du remboursement",
        "Please confirm the refund": "Veuillez confirmer le remboursement",
        "Items selection": "Sélection des éléments",
        "New refund": "Nouveau remboursement",
        "Refund type": "Type de remboursement",
        "Cash": "Cash",
        "Payment date": "Date du paiement",
        "Amount": "Montant",
        "Reason2": "Raison",
        "Refund reason": "Raison du remboursement",
        "Items to be refunded": "Eléments à rembourser",
        "Amount to be refunded to patient": "Montant à rembourser au patient",
        "Refunds history": "Historique des remboursements",
        "Total": "Total",
        "Unable to save the refund, try again later": "Impossible d'enregistrer le remboursement, veuillez réessayer plus tard",
        "Invalid amount": "Montant invalide",
        "Please confirm the payment of an amount of": "Veuillez confirmer le payment d'un montant de",
        "New payment": "Nouveau paiement",
        "Collected amount": "Montant collecté",
        "Refund date": "Date du remboursement",
        "Payments history": "Historique des paiements",
        "Please, select a patient": "Veuillez choisir un patient",
        "Please, select a pharmacy": "Veuillez choisir une pharmacie",
        "Please wait": "Veuillez patienter",
        "Item": "Elément",
        "Unit price": "Prix unitaire",
        "Something went wrong": "Quelque chose a mal tourné",
        "The page you requested cannot be found": "La page que vous avez demandée est introuvable",
        "Please go back to application to continue": "Veuillez retourner à l'application pour continuer",
        "Go back to application": "Retourner à l'application",
        "Ignore": "Ignorer",
        "Keep me connected": "Gardez-moi connecté",
        "Your session will expire in less than 5 minutes": "Votre session va expirer dans moins de 5 minutes",
        "Your session will expire in less than 2 minutes": "Votre session va expirer dans moins de 2 minutes",
        "Your session will expire in few seconds": "Votre session va expirer dans quelques secondes",
        "Your session has expired": "Votre session a expiré",
        "Operation in progress... please wait": "Opération en cours... veuillez patienter",
        "Print receipt": "Imprimer le reçu",
        "Download": "Téléchargement",
        "Stock sheet": "Fiche de stock",
        "Print": "Imprimmer",
        "Info": "Info",
        "Success": "Succès",
        "Error": "Erreur",
        "Tech patient": "Patient tech.",
        "Total Value": "Valeur totale",
        "Dashboard": "Tableau de bord",
        "Incomes": "Revenus",
        "Sales": "Ventes",
        "Summary": "Synthèse",
        "Filter by": "Filtrer par",
        "Outline": "Aperçu",
        "Total sales": "Total des ventes",
        "Sales distribution": "Répartition des ventes",
        "Discarding changes": "Annulation des modifications",
        "Total incomes": "Total des entrées",
        "Incomes per cahier": "Entrées par caissier",
        "Category": "Catégorie",
        "Refunds distribution": "Répartition des remboursements",
        "Refunds per cashier": "Remboursements par caissier",
        "Incomes distribution": "Distribution entrées",
        "File size must not exceed": "La taille du fichier ne doit pas excéder",
        "actual size is": "la taille actuelle est de",
        "Data import/export": "Import/export des données",
        "Drag and drop a file here to import or use one of the following options": "Glissez-déposez un fichier ici pour l'importer ou utilisez l'une des options suivantes",
        "Export": "Exporter",
        "Export2": "Export",
        "Initialisation": "Initialisation",
        "Completion": "Finalisation",
        "Import": "Import",
        "Import2": "Importer",
        "File selection": "Sélecion du fichier",
        "We are ready to proceed": "Tout est prêt pour commencer",
        "Click on": "Cliquez sur",
        "bellow to start the import of your data": "ci-dessous pour commencer l'importation de vos données",
        "Your data have been imported successfully, details are shown bellow": "Vos données ont été importées avec succès, les détails sont disponibles ci-dessous",
        "Imported": "Importé",
        "Continue": "Continuer",
        "accountsCreatedCount": "Comptes",
        "medicUnitsCreatedCount": "Unités",
        "medicTypesCreatedCount": "Types de médicaments",
        "medicinesCreatedCount": "Médicaments",
        "examsCreatedCount": "Examens",
        "examTypesCreatedCount": "Type d'examens",
        "operationsCreatedCount": "Opérations",
        "operationTypesCreatedCount": "Types de chirurgies",
        "servicesCreatedCount": "Autres services",
        "Medicines types": "Types de médicaments",
        "Medicines units": "Unités",
        "What do you want to export?": "Que voulez-vous exporter?",
        "All": "Tout",
        "Your data is ready": "Vos données sont prêtes",
        "The following items will be exported": "Les éléments suivants vont être exportés",
        "Your data have been exported successfully": "Vos données ont été exportées avec succès",
        "New prescription": "Nouvelle prescription",
        "New account": "Nouveau compte",
        "New discount plan": "Nouveau plan de réduction",
        "New service": "Nouveau service",
        "New prices list": "Nouvelle liste de prix",
        "New exam type": "Nouveau type d'examen",
        "New exam": "Nouvel examen",
        "New operation type": "Nouveau type de chirurgie",
        "New surgery": "Nouvelle chirurgie",
        "New supplier": "Nouveau fournisseur",
        "New ward": "Nouveau pavillon",
        "New inventory": "Nouvel inventaire",
        "New medicine type": "Nouveau type de médicament",
        "New unit": "Nouvelle unité",
        "New medicine": "Nouveau médicament",
        "New order": "Nouvelle commande",
        "New requisition": "Nouvelle demande de réquisition",
        "Unknown occupation": "Profession inconnu",
        "Unknown ward": "Pavillon inconnu",
        "Unknown phone number": "Numéro de téléphone inconnu",
        "Unknown address": "Adresse inconnue",
        "I confirm that payment has been received": "Je confirme que le paiement a bien été encaissé",
        "Cash receipt": "Encaissement",
        "Collected": "Encaissé",
        "This is a demo with limited access to some features.": "Ceci est une démo avec accès limité à certaines fonctionnalités.",
        "The image will be permanently deleted": "L'image sera définitivement supprimée",
        "Yes": "Oui",
        "No": "Non",
        "Expenses": "Dépenses",
        "My expenses": "Mes dépenses",
        "All expenses": "Toutes les dépenses",
        "Date in the future not allowed": "Date dans le futur non autorisée",
        "Employee is": "Employé:",
        "By employee" : "Par l'employé",
        "By hospital": "Par l'hôpital",
        "Paid by employee" : "Payé par l'employé",
        "Paid by hospital": "Payé par l'hôpital",
        "Filter expenses": "Filtrer les dépenses",
        "Employee": "Employé",
        "SUBMITTED": "Envoyé",
        "REIMBURSED": "Remboursé",
        "TO_REIMBURSE": "A rembourser",
        "TO_SUBMIT": "A envoyer",
        "Clear all filter": "Effacer tout",
        "Apply filter": "Appliquer le filtre",
        "To be submitted": "A envoyer",
        "Under validation": "En cours de validation",
        "To be reimbursed": "A rembourser",
        "New expense": "Nouvelle dépense",
        "Paid by": "Payé par",
        "Receipt": "Reçu",
        "Add a new expense": "Ajouter une nouvelle dépense",
        "Is this expense for an other employee?": "S'agit-il d'une dépense pour un autre employé ?",
        "No, it's for me": "Non, c'est pour moi",
        "Expense date": "Date de la dépense",
        "Paid by employee (to reimburse)": "Payé par l'employé (à rembourser)",
        "Payer": "Payeur",
        "The employee": "L'employé",
        "The hospital": "L'hôpital",
        "Submit": "Envoyer",
        "Reimburse": "Rembourser",
        "Upload": "Téléverser",
        "Please, confirm you want to continue executing this action": "Veuillez confirmer que vous souhaitez poursuivre l'exécution de cette action.",
        "Receipt not available": "Reçu non disponible",
        "No file selected": "Aucun fichier sélectionné",
        "Loading, please wait...": "Chargement, veuillez patienter...",
        "Value must be greater than 0": "La valeur doit être supérieure à 0",
        "Discount rate per unit": "Taux de réduction à l'unité",
        "Purchase price per unit": "Prix d'achat à l'unité",
        "Total units": "Nombre total d'unités",
        "Total expenses": "Total des dépenses",
        "Expenses distribution": "Distribution des dépenses"
    }
}

export default translation_fr;