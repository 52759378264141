import RoleType from "../../../utils/enums/RoleType";

export default class Role {
	public id?: number;	
	public version: number;
	public type: RoleType;	
	public description?: string;

	constructor(type: RoleType, version: number = 1) {
		this.type = type;
		this.version = version;
	}
}
