import RefundType from "../../../utils/enums/RefundType";
import UserView from "../../admin/model/UserView";
import Ordonnance from "./Ordonnance";
import RefundItem from "./RefundItem";

export default class Refund {
	public id?: number;	
	public version: number;
	public refCode?: string;	
	public ordonnance: Ordonnance;	
	public refundDate?: Date;	
	public type?: RefundType;
	public amount: number;	
	public cashier: UserView;	
	public reason: string;	
	public details?: Array<RefundItem>;

	constructor(ordonnance: Ordonnance, amount: number, cashier: UserView, reason: string, version: number = 1) {
		this.ordonnance = ordonnance;
		this.amount = amount;
		this.cashier = cashier;
		this.reason = reason;
		this.version = version;
	}

}
