import ApiHelper from "../../api/ApiHelper";
import CommonMappers from "../common/CommonMappers";
import CashRefundBean from "./model/CashRefundBean";
import ExpenseBean from "./model/ExpenseBean";
import IncomeBean from "./model/IncomeBean";
import PaginatedResultOfExpenseBeans from "./model/PaginatedResultOfExpenseBeans";
import Parameter from "./model/Parameter";
import RefundsBean from "./model/RefundsBean";
import Role from "./model/Role";
import SalesBean from "./model/SalesBean";
import SalesByCategoryBean from "./model/SalesByCategoryBean";
import User from "./model/User";
import UserRole from "./model/UserRole";
import UserToken from "./model/UserToken";
import UserView from "./model/UserView";

const AdminMappers = {
    mapUser: function(data: any) {
        if(!data) return undefined;
        let user = new User(data.login, data.password, data.lastName, data.enabled, data.version);
        user.address = data.address;
        user.firstName = data.firstName;
        user.id = data.id;
        user.phone = data.phone;
        user.ward = CommonMappers.mapWard(data.ward);
        user.profileImage = data.profileImage;
        user.profession = data.profession;
        user.bannerFilename = data.bannerFilename;
        user.lastConnectionDate = data.lastConnectionDate? new Date(data.lastConnectionDate) : undefined;
        return user;
    },
    mapUserView: function(data: any) {
        if(!data) return undefined;
        let user = new UserView(data.login, data.lastName, data.version);
        user.address = data.address;
        user.firstName = data.firstName;
        user.id = data.id;
        user.phone = data.phone;
        user.enabled = data.enabled;
        user.ward = CommonMappers.mapWard(data.ward);
        user.profileImage = data.profileImage;
        user.profession = data.profession;
        user.bannerFilename = data.bannerFilename;
        user.lastConnectionDate = data.lastConnectionDate? new Date(data.lastConnectionDate) : undefined;
        return user;
    },
    mapRole: function(data: any) {
        if(!data) return undefined;
        let role = new Role(data.type, data.version);
        role.id = data.id;
        role.description = data.description;
        return role;
    },
    mapUserToken: function(data: any) {
        if(!data) return undefined;
        let user = this.mapUserView(data.user);
        let uToken = undefined;
        if(user && data.roles) {
            uToken = new UserToken(
                data.token, 
                data.refreshToken,
                user, 
                data.roles.map((r: any) => this.mapRole(r)))
        }
        return uToken;
    },
    mapUserRole: function(data: any) {
        if(!data) return undefined;
        let user = this.mapUserView(data.user);
        let role = this.mapRole(data.role);
        if(!user || !role) return undefined;

        let userRole = new UserRole(user, role, data.version);
        userRole.id = data.id;
        return userRole;
    },
    mapParameter: function(data: any) {
        if(!data) return undefined;
        let param: Parameter = new Parameter(
            data.name,
            data.scope,
            data.defaultValue,
            data.type,
            data.version
        );
        param.id = data.id;
        param.value = data.value;
        param.description = data.description;
        return param;
    },
    mapSalesBean: function(data: any) {
        if(!data) return undefined;
        return new SalesBean(
            data.itemType,
            data.item,
            data.quantity,
            data.totalAmount
        )
    },
    mapSalesByCategoryBean: function(data: any) {
        if(!data) return undefined;
        return new SalesByCategoryBean(
            data.category,
            data.totalAmount
        )
    },
    mapIncomeBean: function(data: any) {
        if(!data) return undefined;
        return new IncomeBean(
            new Date(data.incomeDate),
            data.cashier,
            data.amount
        )
    },
    mapRefundsBean: function(data: any) {
        if(!data) return undefined;
        return new RefundsBean(
            data.item,
            data.quantity,
            data.totalAmount
        )
    },
    mapCashRefundBean: function(data: any) {
        if(!data) return undefined;
        return new CashRefundBean(
            new Date(data.refundDate),
            data.cashier,
            data.amount
        )
    },
    mapPaginatedResultOfExpenseBeans: function(data: any) {
        let pageResult = ApiHelper.pageResultMapper<ExpenseBean>(data, (it) => it as ExpenseBean);
        if(pageResult !== undefined) {
            let output = new PaginatedResultOfExpenseBeans(
                pageResult.page,
                pageResult.totalPages,
                pageResult.totalElements,
                pageResult.content
            );
            output.totalAmountPaidByEmployee = data.totalAmountPaidByEmployee;
            output.totalAmountPaidByHospital = data.totalAmountPaidByHospital;
            return output;
        }
        return undefined;
    }
}

export default AdminMappers;